import { Icon, Stack, Stepper } from 'ui/core'
import { useCheckout } from 'lib/checkout'
import { StickySidebarContent } from '../StickySidebarContent'
import { CheckoutShipping } from '../CheckoutShipping'
import { CheckoutPayment } from '../CheckoutPayment'
import { CheckoutConfirmation } from '../CheckoutConfirmation'
import { CheckoutSuccess } from '../CheckoutSuccess'
import { CheckoutTotal } from '../CheckoutTotal'
// import { Swap } from '../Swap'

interface CheckoutProps {
  onSuccessContinue?: () => void,
  testID?: string,
}

const Checkout = ({ onSuccessContinue, testID }: CheckoutProps) => {
  const checkout = useCheckout()
  return (
    <StickySidebarContent
      testID={testID}
      reverseWhenXs
      sidebar={(
        <Stack>
          {/* <Swap /> */}
          <CheckoutTotal />
        </Stack>
      )}
      content={(
        <Stepper active={checkout.activeStep}>
          <Stepper.Step label="Shipping" icon={<Icon name="truckDelivery" />}>
            <CheckoutShipping />
          </Stepper.Step>
          <Stepper.Step label="Payment" icon={<Icon name="creditCard" />}>
            <CheckoutPayment />
          </Stepper.Step>
          <Stepper.Step label="Confirmation" icon={<Icon name="checks" />}>
            <CheckoutConfirmation />
          </Stepper.Step>
          <Stepper.Completed>
            <CheckoutSuccess onContinue={onSuccessContinue} />
          </Stepper.Completed>
        </Stepper>
      )}
    />
  )
}

export default Checkout
