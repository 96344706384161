import { formatTestID } from 'core/utils'
import { Button, ButtonProps, Group, GroupProps } from 'ui/core'

export interface StepNavButtonsProps
  extends Pick<ButtonProps, 'size'>,
  Omit<GroupProps, 'children'> {
  back?: Omit<ButtonProps, 'type'> & { onClick?: () => void, type?: 'button' | 'submit' | 'reset' },
  next: Omit<ButtonProps, 'type'> & { onClick?: () => void, type?: 'button' | 'submit' | 'reset' },
  testID?: string,
}

const StepNavButtons = ({ back, next, size, testID, ...rest }: StepNavButtonsProps) => (
  <Group
    grow
    mt="xl"
    {...rest}
    data-testid={testID}
  >
    {back && (
      <Button
        size={size}
        {...back}
        variant="default"
        data-testid={formatTestID(testID, 'back')}
      >
        {back.children || 'Back'}
      </Button>
    )}
    <Button size={size} {...next} data-testid={formatTestID(testID, 'next')}>
      {next.children || 'Next'}
    </Button>
  </Group>
)

export default StepNavButtons
