import { Button, ButtonProps, Icon } from 'ui/core'
import { useCart } from 'lib/cart'
import { CartItem } from 'lib/cart/types'
import { useRouter } from 'next/router'
import { trackProducts } from 'lib/analytics'
import { convertedObjectIDsAfterSearch, convertedProductIDs } from 'lib/algolia'
import { getQueryID } from 'lib/search'

interface AddToCartButtonProps extends ButtonProps {
  getItem: () => CartItem | undefined,
  testID?: string,
}

const AddToCartButton = ({ getItem, testID, ...rest }: AddToCartButtonProps) => {
  const cart = useCart()
  const router = useRouter()

  const addToCart = (e: any) => {
    e.stopPropagation()
    e.preventDefault()

    const item = getItem()
    if (!item)
      return

    cart.addItem({
      ...item,
      checkout: true,
    })

    const queryID = getQueryID()
    if (queryID) {
      convertedObjectIDsAfterSearch(
        [item.product.id],
        'pdpProductAddedToCartAfterSearch',
        queryID,
      )
    } else {
      convertedProductIDs([item.product.id], 'pdpProductAddedToCart')
    }

    trackProducts('shop_add_item_to_cart', {
      products: [
        {
          ...item.product,
          price: item.price,
          quantity: item.quantity,
          variant: item.variants?.map((variant) => variant.value).join(', '),
        },
      ],
    })

    router.push('/shop/cart')
  }

  return (
    <Button
      onClick={addToCart}
      {...rest}
      leftSection={<Icon name="shoppingCartPlus" />}
      data-testid={testID}
    >
      Add to cart
    </Button>
  )
}

export default AddToCartButton
