import { useState, ReactNode } from 'react'
import { capitalize, compressFile } from 'core/utils'
import { SELL_REQUEST_IMAGE_SIDES } from 'model'
import SidePhotos from './SidePhotos'
import { Photo } from './types'

export interface UploadPhotosState {
  sides: Photo[],
  more: Photo[],
}

export interface UploadPhotosProps {
  error?: ReactNode,
  readonly?: boolean,
  initialValue?: UploadPhotosState,
  onChange?: (photos: UploadPhotosState) => void,
  testID?: string,
}

const UploadPhotos = ({
  initialValue,
  readonly,
  error,
  onChange,
  testID,
}: UploadPhotosProps) => {
  const [photos, setPhotos] = useState(() => {
    if (initialValue)
      return initialValue
    const sides: Photo[] = SELL_REQUEST_IMAGE_SIDES.map((side) => ({
      side,
      isReceipt: false,
      label: `${capitalize(side)} side`,
    }))
    const more: Photo[] = []
    return {
      sides,
      more,
    }
  })

  const updatePhotos = (update: UploadPhotosState) => {
    setPhotos(update)
    onChange?.(update)
  }

  const addPhoto = (file: File, index: number, list: keyof UploadPhotosState) => {
    photos[list][index] = {
      ...photos[list][index],
      file,
      compressed: compressFile(file),
    }

    updatePhotos({
      ...photos,
      [list]: [...photos[list]],
    })
  }

  const removePhoto = (index: number, list: keyof UploadPhotosState) => {
    photos[list][index] = { ...photos[list][index], file: undefined }

    updatePhotos({
      ...photos,
      [list]: [...photos[list]],
    })
  }

  return (
    <SidePhotos
      photos={photos.sides}
      error={error}
      readonly={readonly}
      onChange={(file, index) =>
        file ? addPhoto(file, index, 'sides') : removePhoto(index, 'sides')}
      testID={testID}
    />
  )
}

export default UploadPhotos
