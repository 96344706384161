import { BankAccount } from 'model'
import { FirebaseQueryProps, firebaseQueryBuilder } from '@resellam/firebase'
import { getDocs } from 'firebase/firestore'
import useSWR from 'swr'

export const useQueryBankAccounts = ({
  where,
  orderBy,
  limit = 10,
  skip,
}: FirebaseQueryProps & { skip?: boolean } = {}) => {
  const query = firebaseQueryBuilder<BankAccount>('bank-accounts', { where, orderBy, limit })

  return useSWR<BankAccount[]>(query && !skip ? 'bank-accounts' : null, async () => {
    const snap = await getDocs(query)
    const { docs } = snap
    return docs.map((document) => document.data())
  })
}
