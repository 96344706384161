import { Text, Group, Card, IconButton, Switch } from 'ui/core'
import { Address } from 'model'
import { useNotifications, usePopup } from 'core/hooks'
import { useAuth } from '@resellam/auth'
import { useDeleteDocument } from '@resellam/firebase'
import { useSetPrimaryAddress } from 'lib/user'
import { useSWRConfig } from 'swr'

interface AddressCardProps {
  address: Address,
  onDelete?: (address: Address) => void,
  onSetPrimary?: (address: Address) => void,
}

const AddressCard = ({ address, onSetPrimary, onDelete }: AddressCardProps) => {
  const { mutate } = useSWRConfig()
  const { user } = useAuth()
  const popup = usePopup()
  const notifications = useNotifications()
  const [deleteAddress, deleteAddressState] = useDeleteDocument()
  const [setPrimaryAddress] = useSetPrimaryAddress()
  const isPrimary = user?.primaryAddressId === address.id

  const updatePrimaryAddress = async (addressId: string | null) => {
    const optimisticData = { ...user, primaryAddressId: addressId }
    const options = { optimisticData, rollbackOnError: true, populateCache: false }
    return mutate(user?.id, () => setPrimaryAddress({ id: addressId }), options)
  }

  const setPrimary = async () => {
    if (isPrimary)
      return
    const success = await updatePrimaryAddress(address.id)
    notifications.show({
      variant: success ? 'success' : 'error',
      action: 'update',
      entity: 'primary address',
    })
    if (success)
      onSetPrimary?.(address)
  }

  const performDelete = async () => {
    const deleted = await deleteAddress({ collection: 'addresses', id: address.id })
    notifications.show({
      variant: deleted ? 'success' : 'error',
      action: 'delete',
      entity: 'address',
    })

    if (isPrimary) {
      updatePrimaryAddress(null).catch(() => {})
    }

    if (deleted)
      onDelete?.(address)
  }

  const openConfirmModal = () =>
    popup.show({
      variant: 'confirmation',
      title: 'Delete address',
      message: 'Are you sure you want to delete this address?',
      confirm: {
        title: 'Delete',
        onClick: () => performDelete(),
        color: 'red',
      },
    })

  return (
    <Card>
      <Group justify="space-between" wrap="nowrap">
        <Text lineClamp={1}>
          {[address.street, address.lga, address.state].filter((val) => val).join(', ')}
        </Text>
        <Group
          justify="right"
          align="center"
          gap="md"
          style={{ flexShrink: 0 }}
        >
          <Switch
            title={isPrimary ? 'Primary' : 'Make primary'}
            checked={isPrimary}
            onChange={setPrimary}
          />
          <IconButton
            name="trash"
            color="red"
            title="Delete"
            size="lg"
            onClick={openConfirmModal}
            loading={deleteAddressState.isRunning}
          />
        </Group>
      </Group>
    </Card>
  )
}

export default AddressCard
