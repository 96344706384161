import { Stack, Text } from 'ui/core'
import Link from 'next/link'
import { CatalogueCard, CatalogueCardProps } from 'components'
import { Image } from 'core/components'

interface BrandCardProps extends Omit<CatalogueCardProps, 'children'> {
  href: string,
  title?: string,
  logo?: string,
  imageWidth?: number,
  imageHeight?: number,
  onClick?: () => void,
}

const BrandCard = ({
  href,
  title,
  logo,
  imageWidth = 100,
  imageHeight = 100,
  onClick,
  ...rest
}: BrandCardProps) => (
  <CatalogueCard
    component={Link}
    href={href}
    onClick={onClick}
    {...rest}
  >
    <Stack align="center">
      <Image
        src={logo}
        alt={title || ''}
        width={imageWidth}
        height={imageHeight}
      />
      {title && <Text align="center">{title}</Text>}
    </Stack>
  </CatalogueCard>
)

export default BrandCard
