import { usePagination, useSearchBox } from 'react-instantsearch-core'
import {
  BRAND_FACET_ATTRIBUTE,
  CATEGORY_FACET_ATTRIBUTE,
  CONDITION_FACET_ATTRIBUTE,
} from 'lib/algolia'
import { useRefinementList } from '../../RefinementList'
import { usePriceMenu } from '../../PriceMenu'
import { useSortBy } from '../../SortBy'
import { useHitsPerPage } from '../../HitsPerPage'
import { useConfigure } from '../../Configure'

const VirtualFilters = () => {
  useRefinementList({ attribute: BRAND_FACET_ATTRIBUTE })
  useRefinementList({ attribute: CATEGORY_FACET_ATTRIBUTE })
  useRefinementList({ attribute: CONDITION_FACET_ATTRIBUTE })
  usePriceMenu()
  useSortBy()
  useSearchBox()
  usePagination()
  useHitsPerPage()
  useConfigure()
  return null
}

export default VirtualFilters
