import { Catalogue, CategoryCard, SectionTitle } from 'components'
import { Category } from 'model'
import Link from 'next/link'
import { groupCategories } from 'helpers/category'
import { useMemo } from 'react'
import analytics from 'lib/analytics'
import { Box } from 'ui/core'

const ShopCategories = ({ categories }: { categories: Category[] }) => {
  const groupedCategories = useMemo(() => Object.values(groupCategories(categories)), [categories])
  return (
    <Box w="100%">
      <SectionTitle title="Shop categories" />
      <Catalogue cols={{ base: 1, sm: 2 }}>
        {groupedCategories.map((category) => (
          <CategoryCard
            h={{ base: 168, md: 202 }}
            key={category.id}
            component={Link}
            href={`/shop?category=${category.title}`}
            image={category.image}
            title={category.title}
            onClick={() =>
              analytics.track('shop_view_category', {
                promotions: [
                  {
                    id: category.id,
                    name: category.title,
                  },
                ],
              })}
          />
        ))}
      </Catalogue>
    </Box>
  )
}

export default ShopCategories
