import {
  Button,
  Group,
  IconButton,
  Indicator,
  Transition,
  useMantineTheme,
} from 'ui/core'
import {
  AppHeader as CoreAppHeader,
  BrandLink,
  UserAvatar,
} from 'core/components'
import { ProductSearch } from 'components'
import { ReactNode, useMemo } from 'react'
import { useAuth } from 'hooks/use-auth'
import Link from 'next/link'
import { useMediaQuery } from 'ui/hooks'
import RightMenu from '../RightMenu'
import { useRouter } from 'next/router'

export interface AppHeaderProps {
  showSearch: boolean,
  rightSection?: ReactNode,
}

const AppHeader = ({ showSearch, rightSection }: AppHeaderProps) => {
  const { loading, user, requireAuth } = useAuth()
  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const router = useRouter()
  const isHome = router.pathname === '/'

  const right = useMemo(() => {
    const shopButton = (
      <Indicator
        key="app-layout-shop-icon"
        inline
        label="New"
        color="red"
        size={20}
        offset={4}
      >
        <IconButton
          component={Link}
          href="/shop"
          name="buildingStore"
          variant="default"
          size="xl"
          aria-label="Shop"
        />
      </Indicator>
    )

    if (loading)
      return null

    const items = []

    if (isXs) {
      if (user && !rightSection) {
        items.push(<UserAvatar key="app-layout-user-avatar" />)
      } else if (!user && !rightSection) {
        items.push(shopButton)
      }
    } else {
      if (!rightSection) {
        items.push(shopButton)
      }
      items.push(
        user ? (
          <RightMenu key="app-layout-right-menu" />
        ) : (
          <Button key="app-layout-get-started" onClick={requireAuth}>
            Get Started
          </Button>
        ),
      )
    }

    if (rightSection) {
      items.push(<div key="app-layout-right-section">{rightSection}</div>)
    }

    return (
      <Group gap="md" align="center">
        {items}
      </Group>
    )
  }, [loading, user, isXs, rightSection, requireAuth])

  const center = (
    <Transition mounted={showSearch} transition="slide-up">
      {(styles) => (
        <div style={styles}>
          <ProductSearch withinPortal={!isHome} />
        </div>
      )}
    </Transition>
  )

  const left = <BrandLink withTitle={!isXs} />

  return (
    <CoreAppHeader
      leftSection={left}
      centerSection={center}
      rightSection={right}
    />
  )
}

export default AppHeader
