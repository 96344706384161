import { Product } from 'model'
import { Anchor } from 'ui/core'
import { CreateSellRequest } from 'lib/sell-request'
import { CreateSellRequestData } from './types'
import { Photo } from './UploadPhotos'

const smartphones = [
  'oneplus',
  'nokia',
  'google',
  'xiaomi',
  'samsung',
  'oppo',
  'huawei',
  'motorola',
  'asus',
]

const imeiHelpMap: Record<string, Record<string, any>> = {
  smartphone: {
    apple: 'https://support.apple.com/en-us/HT204073',
    ...smartphones.reduce(
      (acc, cur) => ({ ...acc, [cur]: `https://www.imei.info/how-to-find-imei/${cur}/` }),
      {},
    ),
  },
  tablet: {
    apple: 'https://support.apple.com/en-us/HT204073',
  },
  wearable: {
    apple: 'https://support.apple.com/en-us/HT204520',
  },
}

const serialHelpMap: Record<string, Record<string, any>> = {
  smartphone: {
    apple: 'https://support.apple.com/en-us/HT204073',
  },
  tablet: {
    apple: 'https://support.apple.com/en-us/HT204073',
  },
  wearable: {
    apple: 'https://support.apple.com/en-us/HT204520',
  },
  laptop: {
    apple: 'https://support.apple.com/en-us/HT201608',
    hp: 'https://support.hp.com/us-en/document/ish_2039298-1862169-16',
  },
}

const getHelp = (
  obj: Record<string, Record<string, any>>,
  suffix: string,
  product?: Product,
  fallback?: string,
) => {
  let link = fallback
  const brand = product?.brand?.slug
  const category = product?.category?.slug
  if (brand && category) {
    link = obj[category][brand] || link
  }
  if (!link)
    return null
  return (
    <Anchor href={link} target="_blank">
      How to find {suffix}
    </Anchor>
  )
}

export const getIMEIHelp = (product?: Product) =>
  getHelp(imeiHelpMap, 'IMEI number', product, 'https://www.imei.info/faq-what-is-IMEI/')

export const getSerialNoHelp = (product?: Product) =>
  getHelp(serialHelpMap, 'Serial number', product)

export const getSimpleProduct = (product: Product) => product

const photosToImages = (photos?: Photo[]) =>
  (photos || [])
    .filter(({ compressed }) => compressed)
    .map(({ isReceipt, side, compressed }) => compressed!.then((file) => ({ isReceipt, side, file })))

export const buildSellRequest = async (product: Product, data: CreateSellRequestData) => {
  const photos = data.photos?.photos
  const createData: CreateSellRequest = {
    ...data.details,
    ...data.issues,
    upgrade: data.details?.upgrade
      ? {
          title: data.details?.upgrade?.title,
          variants: data.details?.upgrade?.variants,
          productId: data.details?.upgrade?.product?.id,
        }
      : undefined,
    product: getSimpleProduct(product),
    images: await Promise.all([...photosToImages(photos?.sides), ...photosToImages(photos?.more)]),
  }
  return createData
}
