import { Address, Payment, SellRequest, Shipping } from 'model'
import useTask, { TaskState } from '@resellam/hooks/hooks/use-task'
import { sellRequestConverter, useSellRequest } from '@resellam/sell-request'
import { useUpdateDocument } from '@resellam/firebase'
import { generateReference, pay } from 'helpers/paystack'
import { logger } from '@resellam/logger'

export type RequestPickupData = {
  date: Date,
  address: Address,
  payment: Payment,
}

interface RequestPickup {
  pickup: RequestPickupData,
}

const useRequestPickup = (): [
  (payment: RequestPickup) => Promise<PaystackTransaction | null>,
  TaskState<PaystackTransaction>,
] => {
  const [updateDoc] = useUpdateDocument<SellRequest>()
  const { sellRequest, updateSellRequest } = useSellRequest()
  const [taskFn, taskState] = useTask<PaystackTransaction>()

  const run = ({ pickup: pickupData }: RequestPickup) =>
    taskFn(async () => {
      const sellRequestId = sellRequest?.id
      const { amount, method } = pickupData.payment

      if (!sellRequestId || !amount || !method) {
        logger.error('Missing payment parameters')
        throw new Error('Missing payment parameters')
      }

      const reference = generateReference()
      const pickup: Shipping = {
        date: pickupData.date,
        addressId: pickupData.address.id,
        payment: {
          amount,
          method,
          paystack: {
            reference,
          },
        },
      }

      await updateDoc({
        id: sellRequestId,
        data: { pickup },
        collection: 'sell-requests',
        converter: sellRequestConverter,
      })

      try {
        const transaction = await pay({
          type: 'SELL_REQUEST_PICKUP',
          reference,
          amount,
          method,
          metadata: {
            pickup: true,
            sell_request_id: sellRequestId,
            custom_fields: [
              {
                display_name: 'Sell request ID',
                variable_name: 'sell_request_id',
                value: sellRequestId,
              },
              {
                display_name: 'Pickup',
                variable_name: 'pickup',
                value: true,
              },
            ],
          },
        })
        if (transaction) {
          const updatedPickup: Shipping = {
            ...pickup,
            payment: {
              ...pickup.payment,
              status: 'SUCCESS',
            },
          }
          await updateDoc({
            id: sellRequestId,
            data: {
              pickup: updatedPickup,
            },
            collection: 'sell-requests',
            converter: sellRequestConverter,
          })
          updateSellRequest({
            pickup: updatedPickup,
          })
        }
        return transaction
      } catch (error) {
        return null
      }
    })

  return [run, taskState]
}

export default useRequestPickup
