import { Group, Text, IconButton, Icon } from 'ui/core'
import { RecentSearchesItem } from '../types'

const RecentSearchHit = ({ hit, onDelete }: { hit: RecentSearchesItem, onDelete?: () => void }) => (
  <Group wrap="nowrap" justify="space-between" align="center">
    <Group gap="xs" align="center">
      <Icon name="clock" size="md" color="gray" />
      <Text>{hit.label}</Text>
    </Group>
    <Group gap="xs" align="center">
      <IconButton
        name="trash"
        iconSize="md"
        onClick={(e) => {
          e.stopPropagation()
          onDelete?.()
        }}
      />
      <IconButton name="arrowUpLeft" iconSize="md" />
    </Group>
  </Group>
)

export default RecentSearchHit
