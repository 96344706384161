import { Text } from 'ui/core'

export interface NoResultsProps {
  query: string,
}

const NoResults = ({ query }: NoResultsProps) => (
  <Text
    color="gray"
    size="md"
    p="xl"
    align="center"
  >
    No results matching &quot;{query}&quot;
  </Text>
)

export default NoResults
