import { Button, Group, Icon, Text } from 'ui/core'
import {
  useConnector,
  useCurrentRefinements,
  useInstantSearch,
} from 'react-instantsearch-core'
import connectStats, {
  type StatsConnectorParams,
  type StatsWidgetDescription,
} from 'instantsearch.js/es/connectors/stats/connectStats'

type UseStatsProps = StatsConnectorParams

export const useStats = (props?: UseStatsProps) =>
  useConnector<StatsConnectorParams, StatsWidgetDescription>(
    connectStats,
    props,
  )

const Stats = () => {
  const stats = useStats()
  const instantSearch = useInstantSearch()
  const currentRefinements = useCurrentRefinements()

  const { query } = instantSearch.indexUiState

  const clearFilters = () => {
    instantSearch.setIndexUiState({ query: undefined })
  }

  if (
    (!currentRefinements.items.length && !query)
    || instantSearch.status === 'stalled'
  ) {
    return <div />
  }

  return (
    <Group gap="xs">
      <Text>
        Showing{' '}
        <Text inline component="span" fw="bold">
          {stats.nbHits}{' '}
          <Text inline fw="normal" component="span">
            {query ? 'results for' : 'results'}
          </Text>{' '}
          {query && (
            <Text inline component="span">
              {query}
            </Text>
          )}
        </Text>
      </Text>
      {query && (
        <Button
          variant="outline"
          size="compact-sm"
          leftSection={<Icon name="x" size="sm" />}
          display="inline-flex"
          onClick={clearFilters}
          styles={{ section: { marginRight: 4 } }}
        >
          Clear
        </Button>
      )}
    </Group>
  )
}

export default Stats
