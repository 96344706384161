import { ButtonProps, Card, LoadingOverlay, SimpleGrid } from 'ui/core'
import { formatTestID } from 'core/utils'
import { Product, SellRequestVariant } from 'model'
import { SellRequestDelivery } from '../SellRequestDelivery'
import { SellRequestOfferDetails } from '../SellRequestOfferDetails'
import { SellRequestOfferHeader } from '../SellRequestOfferHeader'
import { useSellRequestFrontImage } from '../SellRequestStatus.utils'

export interface SellRequestOfferLayoutProps {
  swap: boolean,
  price: number,
  product?: Product,
  variants?: SellRequestVariant[],
  onBack?: () => void,
  cardLoading?: boolean,
  confirm?: ButtonProps & { onClick: () => void },
  testID?: string,
}

const SellRequestOfferLayout = ({
  swap,
  price,
  product,
  variants,
  onBack,
  confirm,
  cardLoading = false,
  testID,
}: SellRequestOfferLayoutProps) => {
  const sellRequestImage = useSellRequestFrontImage()

  return (
    <SimpleGrid cols={{ base: 1, sm: 2 }} style={{ alignItems: 'center' }} data-testid={testID}>
      <SellRequestOfferDetails
        swap={swap}
        price={price}
        imageProps={{
          alt: swap ? product?.title || '' : 'Your Device',
          path: swap ? undefined : sellRequestImage,
          height: 360,
          width: 360,
        }}
        condition={product?.condition ?? 'Your Device'}
        product={product}
        variants={variants}
        testID={formatTestID(testID, 'offer-details')}
      />
      <Card pos="relative" shadow="md">
        <LoadingOverlay visible={cardLoading} />
        <SellRequestOfferHeader
          title={swap ? 'Swap' : 'Sell'}
          iconName={swap ? 'repeat' : 'cash'}
          onBack={onBack}
          testID={formatTestID(testID, 'offer-header')}
        />
        <SellRequestDelivery confirm={confirm} testID={formatTestID(testID, 'delivery')} />
      </Card>
    </SimpleGrid>
  )
}

export default SellRequestOfferLayout
