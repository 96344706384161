let queryID: string | undefined = undefined

const getQueryID = () => queryID

const setQueryID = (state: string | undefined) => {
  queryID = state
}

export {
  getQueryID,
  setQueryID,
}
