import { Group, IconButton, Pagination as UIPagination } from 'ui/core'
import { usePagination } from 'react-instantsearch-core'
import { useBreakpoint } from 'core/hooks'
import { useWindowScroll } from 'ui/hooks'

const Pagination = () => {
  const pagination = usePagination()
  const { isSm } = useBreakpoint()
  const [, scrollTo] = useWindowScroll()

  if (!pagination.canRefine)
    return null

  const gotoPage = (page: number) => {
    pagination.refine(page)
    scrollTo({ y: 0 })
  }

  return isSm ? (
    <Group>
      <IconButton
        name="chevronLeft"
        title="Previous"
        onClick={() => gotoPage(pagination.currentRefinement - 1)}
        disabled={pagination.isFirstPage}
      />
      <IconButton
        name="chevronRight"
        title="Next"
        onClick={() => gotoPage(pagination.currentRefinement + 1)}
        disabled={pagination.isLastPage}
      />
    </Group>
  ) : (
    <UIPagination
      siblings={1}
      boundaries={1}
      total={pagination.nbPages}
      value={pagination.currentRefinement + 1}
      onChange={(val) => gotoPage(val - 1)}
    />
  )
}

export default Pagination
