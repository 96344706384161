import { ReactNode } from 'react'
import { formatTestID } from 'ui/utils'
import cx from 'clsx'
import classes from './StickySidebarContent.module.css'

interface StickySidebarContentProps {
  content: ReactNode,
  sidebar: ReactNode,
  reverseWhenXs?: boolean,
  testID?: string,
}

const StickySidebarContent = ({
  content,
  sidebar,
  reverseWhenXs,
  testID,
}: StickySidebarContentProps) => (
  <div
    className={cx(classes.root, reverseWhenXs ? classes.rootReverse : undefined)}
    data-testid={testID}
  >
    <div className={classes.content} data-testid={formatTestID(testID, 'content')}>
      {content}
    </div>
    <div className={classes.sidebar} data-testid={formatTestID(testID, 'sidebar')}>
      {sidebar}
    </div>
  </div>
)

export default StickySidebarContent
