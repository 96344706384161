import { updateDoc } from 'firebase/firestore'
import useTask, { TaskState } from '@resellam/hooks/hooks/use-task'
import { getDocumentRef, defaultConverter } from '@resellam/firebase'

type ReadNotification = {
  notificationId: string,
}

const readNotification = async ({ notificationId }: ReadNotification): Promise<boolean> => {
  const docRef = getDocumentRef('notifications', notificationId).withConverter(defaultConverter)
  const data = { read: true }
  await updateDoc(docRef, data)
  return true
}

export const useReadNotification = (): [
  (readNotification: ReadNotification) => Promise<boolean | null>,
  TaskState<boolean>,
] => {
  const [taskFn, taskState] = useTask<boolean>()
  const create = (data: ReadNotification) => taskFn(() => readNotification(data))
  return [create, taskState]
}
