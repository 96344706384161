import { useAuth } from '@resellam/auth'
import Script from 'next/script'
import { useEffect } from 'react'
import { ref } from 'firebase/storage'
import { useDownloadURL } from 'react-firebase-hooks/storage'
import { getStorage } from '@resellam/firebase'
import { fullName } from 'core/utils'
import useLiveChat from 'hooks/use-live-chat'

const LiveChat = () => {
  const { user } = useAuth()
  const liveChat = useLiveChat()
  const [photoURL] = useDownloadURL(
    user?.photo?.path ? ref(getStorage(), user?.photo?.path) : undefined,
  )

  useEffect(() => {
    window.$crisp = window.$crisp || []
    window.CRISP_WEBSITE_ID = process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID as string
  }, [])

  useEffect(() => {
    if (!photoURL)
      return
    liveChat.setUserPhoto(photoURL)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoURL])

  useEffect(() => {
    if (!user)
      return
    if (user.email)
      liveChat.setUserEmail(user.email)
    if (user.phoneNumber)
      liveChat.setUserPhone(user.phoneNumber)
    if (user.firstName || user.lastName)
      liveChat.setUserName(fullName(user.firstName, user.lastName))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return <Script src="https://client.crisp.chat/l.js" strategy="lazyOnload" />
}

export default LiveChat
