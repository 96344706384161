import { Badge } from 'ui/core'

export interface InStockProps {
  inStock?: boolean,
  testID?: string,
}

const InStock = ({ inStock, testID }: InStockProps) => (
  <Badge variant="outline" color={inStock ? 'green' : 'gray'} data-testid={testID}>
    {inStock ? 'In Stock' : 'Out of Stock'}
  </Badge>
)

export default InStock
