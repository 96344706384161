import { useEffect, useState } from 'react'

const content = [
  {
    text: 'Phone',
    imageUrl: '/images/hero-phone.png',
  },
  {
    text: 'Laptop',
    imageUrl: '/images/hero-laptop.png',
  },
  {
    text: 'Smartwatch',
    imageUrl: '/images/hero-smartwatches.png',
  },
  {
    text: 'Tablet',
    imageUrl: '/images/hero-tablets.png',
  },
  {
    text: 'Gadgets',
    imageUrl: '/images/hero-gadgets.png',
  },
]

const useDynamicContent = () => {
  const [index, setIndex] = useState(0)
  useEffect(() => {
    const intervalId = setInterval(() => setIndex((val) => (val + 1) % content.length), 3000)
    return () => clearInterval(intervalId)
  }, [])
  return content[index]
}

export default useDynamicContent
