import Link from 'next/link'
import { Button, Group, Icon, Title } from 'ui/core'

export interface SectionTitleProps {
  title: string,
  rightAction?: {
    href: string,
    title: string,
  },
}

const SectionTitle = ({ title, rightAction }: SectionTitleProps) => (
  <Group
    mb="xs"
    wrap="nowrap"
    justify="space-between"
    align="center"
  >
    <Title order={3} style={{ WebkitLineClamp: 1 }}>
      {title}
    </Title>
    {rightAction && (
      <Button
        component={Link}
        href={rightAction.href}
        size="compact-md"
        variant="subtle"
        rightSection={<Icon name="arrowRight" />}
      >
        {rightAction.title}
      </Button>
    )}
  </Group>
)

export default SectionTitle
