import { Text, Box, BoxProps } from 'ui/core'
import { Dropzone } from 'core/components'
import { useModals } from 'ui/modals'
import { useSellRequest, useUploadSellRequestPhotos } from '@resellam/sell-request'
import { UploadReceipt } from 'components'
import IssuesForm from './IssuesForm'
import AdditionalInfoForm from './AdditionalInfoForm'
import SellRequestAction from './SellRequestAction'

const SellRequestActions = (props: BoxProps) => {
  const modals = useModals()
  const uploadSellRequestPhotos = useUploadSellRequestPhotos()
  const { sellRequest, updateSellRequest: mutateSellRequest } = useSellRequest()

  const issuesTitle = `${
    sellRequest?.repairs?.length || sellRequest?.damages?.length ? 'Update' : 'Add'
  } issues`

  const openIssuesForm = () => {
    const id = modals.openModal({
      title: issuesTitle,
      children: sellRequest ? (
        <IssuesForm
          sellRequest={sellRequest}
          onCancel={() => modals.closeModal(id)}
          onSuccess={(data) => {
            mutateSellRequest(data)
            modals.closeModal(id)
          }}
        />
      ) : null,
    })
  }

  const openAdditionalInfoForm = () => {
    const id = modals.openModal({
      title: `Add more info`,
      children: sellRequest ? (
        <AdditionalInfoForm
          sellRequest={sellRequest}
          onCancel={() => modals.closeModal(id)}
          onSuccess={(additionalInfo) => {
            mutateSellRequest({ additionalInfo })
            modals.closeModal(id)
          }}
        />
      ) : null,
    })
  }

  return (
    <Box {...props}>
      <Text>Here is what you can do in the meantime</Text>
      <Box style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }} mt="sm">
        <UploadReceipt>
          <SellRequestAction component="div" icon="receipt" title="Upload receipt" />
        </UploadReceipt>
        <Dropzone
          unstyled
          multiple
          style={{ flexGrow: 1 }}
          loading={uploadSellRequestPhotos.addMorePhotosState.isRunning}
          onDrop={(files) => uploadSellRequestPhotos.addMorePhotos(files)}
        >
          <SellRequestAction component="div" icon="photo" title="Upload photos" />
        </Dropzone>
        <SellRequestAction icon="tool" title={issuesTitle} onClick={openIssuesForm} />
        <SellRequestAction
          icon="infoSquare"
          title="Add more info"
          onClick={openAdditionalInfoForm}
        />
      </Box>
    </Box>
  )
}

export default SellRequestActions
