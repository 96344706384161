import { Image } from 'ui/core'
import {
  MorePhotosCard,
  ProductPhoto,
  ProductPhotos as CoreProductPhotos,
  SectionHeader,
} from 'core/components'
import { ProductImage } from 'model'

export interface ProductPhotosProps {
  photos: ProductImage[],
}

const ProductPhotos = ({ photos: images }: ProductPhotosProps) => (
  <div>
    <SectionHeader title="Photos" />
    <CoreProductPhotos
      photos={images}
      renderPhoto={({ photo, height, index, onClick }) => (
        <ProductPhoto key={`${photo.url}-${index}`} label={photo.caption} onClick={onClick}>
          <Image
            fit="contain"
            src={photo.url}
            key={`${photo.url}-${index}`}
            height={height}
            onClick={onClick}
          />
        </ProductPhoto>
      )}
      renderCarouselPhoto={({ photo, height }) => (
        <Image fit="contain" src={photo.url} height={height} />
      )}
      renderMorePhotos={({ photos, height, expand }) => (
        <MorePhotosCard height={height} src={photos[0].url} onClick={expand}>
          {photos.length} more`
        </MorePhotosCard>
      )}
    />
  </div>
)

export default ProductPhotos
