import { Product } from 'model'
import { DiscountedPrice, ProductVariantInput } from 'core/components'
import { getProductVariants, variantsSchema, getFormVariants, transformVariants } from 'core/utils'
import { Button, Group, Icon, Stack, Text } from 'ui/core'
import { useMemo } from 'react'
import { useCart } from 'lib/cart'
import { useForm } from 'core/hooks'
import { zodResolver } from 'ui/form'
import { z } from 'zod'
import Link from 'next/link'
import useProductPrice from 'hooks/use-product-price'
import { ProductCondition } from 'components'
import { QuantitySelect } from '../QuantitySelect'
import { AddToCartButton } from '../AddToCartButton'
import { InStock } from '../InStock'
import { BuyNowButton } from '../BuyNowButton'

interface ProductDetailProps {
  product: Product,
}

const ProductDetail = ({ product }: ProductDetailProps) => {
  const cart = useCart()

  const isNaijaUsed = product.condition === 'Naija Used'
  const validVariants = useMemo(() => product?.variants?.filter((variant) =>
    variant.variantValue?.find(({ value }) => typeof value === 'number'),
  ), [product])
  const variants = getProductVariants(
    validVariants,
    (variantValue) => typeof variantValue.value === 'number',
  )

  const schema = useMemo(
    () =>
      z
        .object({
          quantity: z.number().min(1).max(10),
          variants: variantsSchema(validVariants),
        })
        .strict(),
    [validVariants],
  )

  const form = useForm({
    initialValues: {
      quantity: 1,
      variants: getFormVariants(validVariants, undefined, (variant) => {
        const validValues = variant.variantValue?.filter(({ value }) => typeof value === 'number')
        return validValues?.length === 1 ? validValues?.[0].key : undefined
      }),
    },
    validate: zodResolver(schema),
  })

  const parsedVariants = useMemo(
    () => transformVariants(form.values.variants),
    [form.values.variants],
  )

  const price = useProductPrice({
    product,
    variants: parsedVariants,
  })

  const getItem = () => {
    if (form.validate().hasErrors || !price)
      return undefined
    const parsed = schema.parse(form.values)
    return {
      quantity: parsed.quantity,
      variants: parsed.variants,
      price,
      product,
      checkout: true,
      discount: product.discount,
    }
  }

  return (
    <Stack gap="lg">
      <Stack gap="xs">
        <Text inline size="lg">
          {product?.title}
        </Text>
        <Text inline size="sm">
          By{' '}
          <Text
            inline
            component="span"
            size="sm"
            variant="link"
          >
            {product?.brand?.title}
          </Text>
        </Text>
        <Group gap="xs">
          <InStock inStock={product.inStock} />
          {product.condition && <ProductCondition condition={product.condition} />}
        </Group>
      </Stack>

      <Stack gap="md">
        {variants.map((variant) => (
          <ProductVariantInput
            key={variant.label}
            {...variant}
            {...form.getInputProps(`variants.${variant.label}`)}
            required
          />
        ))}
        {product.inStock && !isNaijaUsed && (
          <QuantitySelect {...form.getInputProps('quantity')} label="Quantity" />
        )}
      </Stack>

      <DiscountedPrice
        direction="row"
        size="xl"
        price={price || 0}
        discount={product.discount}
        quantity={form.values.quantity}
        align="center"
      />

      <Stack gap="md">
        <Group wrap="nowrap" style={{ width: '100%' }}>
          {!cart.isEmpty && (
            <Button
              component={Link}
              href="/shop/cart"
              leftSection={<Icon name="shoppingCart" />}
              variant="default"
              fullWidth
            >
              View cart
            </Button>
          )}
          <AddToCartButton
            fullWidth
            variant="outline"
            getItem={getItem}
            disabled={!product.inStock}
          />
        </Group>
        <BuyNowButton fullWidth getItem={getItem} disabled={!product.inStock} />
      </Stack>
    </Stack>
  )
}

export default ProductDetail
