import { Text } from 'ui/core'
import { forwardRef } from 'react'
import { Address } from 'model'
import { fullName } from 'core/utils'
import { AddressLink, PhoneNumber } from 'core/components'

export interface SearchResultItemProps {
  address: Address,
  testID?: string,
}

const AddressResultItem = forwardRef<HTMLDivElement, SearchResultItemProps>(
  ({ address, testID, ...rest }: SearchResultItemProps, ref) => (
    <div {...rest} ref={ref} data-testid={testID}>
      <Text lineClamp={1}>{fullName(address.firstName, address.lastName)}</Text>
      {address.phoneNumber && <PhoneNumber value={address.phoneNumber} />}
      {address.googlePlaceId && (
        <AddressLink placeName={address.street} placeId={address.googlePlaceId} />
      )}
    </div>
  ),
)

export default AddressResultItem
