import { deleteDoc, doc } from 'firebase/firestore'
import useTask, { TaskState } from '@resellam/hooks/hooks/use-task'
import { getCollectionRef } from '@resellam/firebase'

type DeleteBankAccount = {
  bankAccountId: string,
}

const deleteBankAccount = async ({ bankAccountId }: DeleteBankAccount): Promise<void> => {
  const docRef = doc(getCollectionRef('bank-accounts'), bankAccountId)
  return deleteDoc(docRef)
}

export const useDeleteBankAccount = (): [
  (data: DeleteBankAccount) => Promise<boolean | null>,
  TaskState<boolean | null>,
] => {
  const [taskFn, taskState] = useTask<boolean | null>()
  const run = (data: DeleteBankAccount) =>
    taskFn(() =>
      deleteBankAccount(data)
        .then(() => true)
        .catch(() => false),
    )
  return [run, taskState]
}
