import { Title, Text } from 'ui/core'
import { Page } from 'core/components'
import classes from './OfflinePage.module.css'

const OfflinePage = () => (
  <Page py={80}>
    <div className={classes.label}>OFFLINE</div>
    <Title className={classes.title} fw={900} ta="center">
      You are not connected to the Internet
    </Title>
    <Text
      color="dimmed"
      size="lg"
      align="center"
      mt="xl"
    >
      Try checking the network cables, modem and router or reconnecting to Wi-Fi.
    </Text>
  </Page>
)

export default OfflinePage
