import { Icon, IconButton, Loader, Group, TextInput, TextInputProps } from 'ui/core'
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { useProductSearch } from '../useProductSearch'

interface ProductSearchInputProps extends TextInputProps {
  onCancel?: () => void,
}

const getPlaceholder = (searchType?: string) => {
  switch (searchType) {
    case 'Sell':
      return 'What are you selling?'
    case 'Swap':
      return 'What are you swapping?'
    default:
      return 'What are you buying?'
  }
}

const ProductSearchInput = forwardRef<HTMLInputElement, ProductSearchInputProps>(
  ({ onCancel, ...rest }, ref) => {
    const { autocomplete, searchType, status } = useProductSearch()
    const inputRef = useRef<HTMLInputElement>(null!)
    const stalled = status === 'stalled'
    const loading = status === 'loading'

    useImperativeHandle(ref, () => inputRef?.current, [inputRef])

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, [searchType])

    return (
      <Group gap="xs">
        <TextInput
          {...(autocomplete.getInputProps({} as any) as any)}
          {...rest}
          style={{ flex: 1 }}
          ref={inputRef}
          variant="filled"
          autoFocus
          placeholder={getPlaceholder(searchType)}
          leftSection={
            loading || stalled ? (
              <Loader size="xs" color="gray" />
            ) : (
              <Icon name="search" color="gray" />
            )
          }
        />
        <IconButton
          size="lg"
          variant="default"
          name="x"
          title="Close"
          onClick={onCancel}
        />
      </Group>
    )
  },
)

export default ProductSearchInput
