import { useRouter } from 'next/router'
import {
  ReactNode,
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react'

export interface ShopContextValue {
  searching: boolean,
}

export const ShopContext = createContext<ShopContextValue | undefined>(
  undefined,
)

interface ShopProviderProps {
  children: ReactNode,
}

const ShopProvider = (props: ShopProviderProps) => {
  const router = useRouter()
  const [searching, setSearching] = useState(router.asPath !== '/shop')

  useEffect(() => {
    if (!searching && router.asPath !== '/shop') {
      setSearching(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath])

  const shopContextValue: ShopContextValue = useMemo(
    () => ({
      searching,
    }),
    [searching],
  )

  return <ShopContext.Provider value={shopContextValue} {...props} />
}

export default ShopProvider

export const useShop = (): ShopContextValue => {
  const context = useContext(ShopContext)
  if (!context) {
    throw new Error('useShop must be used within a ShopProvider')
  }
  return context
}
