import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia'
import { useMemo, useState, ReactNode, createContext, useContext, useEffect } from 'react'
import { useRouter } from 'next/router'
import {
  algoliaSearchClient,
  algoliaInsightsPlugin,
  recentSearchesPlugin,
  querySuggestionsPlugin,
  AlgoliaProductHit,
  algoliaInsightsClient,
} from 'lib/algolia'
import {
  createAutocomplete,
  AutocompleteApi,
  AutocompleteState,
  AutocompleteCollection,
} from '@algolia/autocomplete-core'
import { useAuth } from '@resellam/auth'
import { getProductURL } from '../utils'
import { SearchType } from '../types'

export type ProductSearchHit = AlgoliaProductHit
export type ProductSearchCollection = AutocompleteCollection<ProductSearchHit>
type ProductSearch = AutocompleteApi<AlgoliaProductHit, Event, MouseEvent, KeyboardEvent>

interface ProductSearchContextValue {
  searchType?: SearchType,
  setSearchType: (state: SearchType | undefined) => void,
  autocomplete: ProductSearch,
  autocompleteState: AutocompleteState<AlgoliaProductHit>,
}

const ProductSearchContext = createContext<ProductSearchContextValue | undefined>(undefined)

interface ProductSearchProviderProps {
  children: ReactNode,
}

const ProductSearchProvider = (props: ProductSearchProviderProps) => {
  const { user } = useAuth()
  const router = useRouter()
  const [searchType, setSearchType] = useState<SearchType>()

  const [autocompleteState, setAutocompleteState] = useState<AutocompleteState<AlgoliaProductHit>>(
    {} as AutocompleteState<AlgoliaProductHit>,
  )

  useEffect(() => {
    if (user?.id) {
      algoliaInsightsClient('setUserToken', user.id)
    }
  }, [user?.id])

  const autocomplete = useMemo(
    () =>
      createAutocomplete<AlgoliaProductHit>({
        initialState: autocompleteState,
        autoFocus: true,
        openOnFocus: true,
        shouldPanelOpen: () => true,
        async onStateChange({ prevState, state }) {
          // (2) Synchronize the Autocomplete state with the React state.
          setAutocompleteState(state)

          // analytics
          if (state.query && state.query !== prevState.query) {
            const analytics = (await import('lib/analytics')).default
            analytics.track('search', {
              category: 'engagement',
              search_term: state.query,
              search_type: searchType,
            })
          }
        },
        plugins: [algoliaInsightsPlugin, recentSearchesPlugin, querySuggestionsPlugin],
        navigator: {
          navigate({ itemUrl }) {
            router.push(itemUrl)
          },
        },
        getSources() {
          return [
            {
              sourceId: 'products',
              getItemUrl({ item }) {
                return getProductURL(searchType, item)
              },
              getItemInputValue({ item }) {
                return item.title
              },
              getItems({ query }) {
                if (!query)
                  return []
                return getAlgoliaResults({
                  searchClient: algoliaSearchClient,
                  queries: [
                    {
                      indexName: 'products',
                      params: {
                        query,
                        filters: 'condition:NEW',
                        hitsPerPage: 5,
                        clickAnalytics: true,
                        attributesToSnippet: ['title:10'],
                        snippetEllipsisText: '…',
                      },
                    },
                  ],
                })
              },
            },
          ]
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchType],
  )

  const contextValue = useMemo(
    () => ({
      autocomplete,
      autocompleteState,
      searchType,
      setSearchType,
    }),
    [autocomplete, autocompleteState, searchType, setSearchType],
  )

  return <ProductSearchContext.Provider value={contextValue} {...props} />
}

export default ProductSearchProvider

export const useProductSearchContext = (): ProductSearchContextValue => {
  const context = useContext(ProductSearchContext)
  if (!context) {
    throw new Error('useProductSearch must be used within a ProductSearchProvider')
  }
  return context
}
