import { useState } from 'react'
import { useSellRequest } from '@resellam/sell-request'
import { Button, Icon, Stepper } from 'ui/core'
import { formatTestID } from 'core/utils'
import { UploadReceipt, Alert } from 'components'
import { SellRequestPickupPayment } from './SellRequestPickupPayment'
import { SellRequestPickupRequest, SellRequestPickupRequestProps } from './SellRequestPickupRequest'
import { SellRequestPickUpEstimate } from './SellRequestPickUpEstimate'

interface SellRequestPickUpProps {
  testID?: string,
  onRequest: () => void,
}

const SellRequestPickUp = ({ onRequest, testID }: SellRequestPickUpProps) => {
  const { sellRequest } = useSellRequest()
  const [activeStep, setActiveStep] = useState(0)
  const [pickupData, setPickupData] = useState<Partial<SellRequestPickupRequestProps['data']>>({})

  if (!sellRequest?.images?.find((image) => image.isReceipt))
    return (
      <div>
        <Alert mb="md" alertStyle="warning" title="Upload Receipt">
          Please upload your device receipt to allow you to request pickup.
        </Alert>
        <UploadReceipt testID={formatTestID(testID, 'upload-receipt')}>
          <Button fullWidth>Upload receipt</Button>
        </UploadReceipt>
      </div>
    )

  const goBack = () => setActiveStep(activeStep - 1)

  return (
    <Stepper active={activeStep} data-testid={testID}>
      <Stepper.Step label="Details" icon={<Icon name="fileDescription" />}>
        <SellRequestPickUpEstimate
          onNext={(estimate) => {
            setActiveStep(activeStep + 1)
            setPickupData((data) => ({
              ...data,
              date: estimate.date,
              address: estimate.address,
              payment: { ...data?.payment, amount: estimate.amount },
            }))
          }}
          testID={formatTestID(testID, 'estimate')}
        />
      </Stepper.Step>
      <Stepper.Step label="Payment" icon={<Icon name="creditCard" />}>
        <SellRequestPickupPayment
          onBack={goBack}
          onNext={(payment) => {
            setActiveStep(activeStep + 1)
            setPickupData((data) => ({
              ...data,
              payment: { ...data?.payment, method: payment.method },
            }))
          }}
          testID={formatTestID(testID, 'payment')}
        />
      </Stepper.Step>
      <Stepper.Step label="Confirmation" icon={<Icon name="checks" />}>
        <SellRequestPickupRequest
          data={pickupData as Required<SellRequestPickupRequestProps['data']>}
          onBack={goBack}
          onNext={onRequest}
          testID={formatTestID(testID, 'request')}
        />
      </Stepper.Step>
    </Stepper>
  )
}

export default SellRequestPickUp
