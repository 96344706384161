import { updateDoc } from 'firebase/firestore'
import useTask, { TaskState } from '@resellam/hooks/hooks/use-task'
import { getDocumentRef, getAuth } from '@resellam/firebase'
import { sellRequestConverter } from '@resellam/sell-request'
import { SellRequest } from 'model'

type CancelSellRequest = {
  id: string,
  notes?: string,
}

const cancelSellRequest = async ({ id, notes }: CancelSellRequest): Promise<Partial<SellRequest>> => {
  const docRef = getDocumentRef('sell-requests', id).withConverter(sellRequestConverter)
  const data: Partial<SellRequest> = {
    status: {
      name: 'CANCELLED',
      notes,
      createdAt: new Date(),
      createdBy: getAuth().currentUser?.uid,
    },
  }
  await updateDoc(docRef, data)
  return { ...data, id }
}

export const useCancelSellRequest = (): [
  (sellRequest: CancelSellRequest) => Promise<Partial<SellRequest> | null>,
  TaskState<Partial<SellRequest>>,
] => {
  const [taskFn, taskState] = useTask<Partial<SellRequest>>()
  const run = (cancel: CancelSellRequest) => taskFn(() => cancelSellRequest(cancel))
  return [run, taskState]
}
