import { createAlgoliaInsightsPlugin } from '@algolia/autocomplete-plugin-algolia-insights'
import insightsClient from 'search-insights'
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares'
import {
  PRODUCTS_INDEX,
  CLICK_EVENT_NAMES,
  CONVERSION_EVENT_NAMES,
  PRODUCTS_QUERY_SUGGESTIONS_INDEX,
} from '../constant'

type ClickEventName = keyof typeof CLICK_EVENT_NAMES
type ConversionEventName = keyof typeof CONVERSION_EVENT_NAMES

insightsClient('init', {
  appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string,
  apiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY as string,
  useCookie: true,
})

export const algoliaInsightsClient = insightsClient

export const algoliaInsightsPlugin = createAlgoliaInsightsPlugin({
  insightsClient,
  onSelect({ insights, insightsEvents }) {
    const events = insightsEvents.map((insightsEvent) => {
      switch (insightsEvent.index) {
        case PRODUCTS_QUERY_SUGGESTIONS_INDEX: {
          return {
            ...insightsEvent,
            eventName: CLICK_EVENT_NAMES.autocompleteSuggestionClicked,
          }
        }
        case PRODUCTS_INDEX: {
          return {
            ...insightsEvent,
            eventName: CLICK_EVENT_NAMES.autocompleteProductClicked,
          }
        }
        default: {
          return {
            ...insightsEvent,
            eventName: CLICK_EVENT_NAMES.autocompleteItemClicked,
          }
        }
      }
    })

    insights.clickedObjectIDsAfterSearch(...events)
  },
})

export const algoliaInsightsMiddleware = createInsightsMiddleware({
  insightsClient,
  insightsInitParams: {
    useCookie: true,
  },
})

export const clickedProductIDs = (objectIDs: string[], eventName: ClickEventName) =>
  insightsClient('clickedObjectIDs', {
    index: PRODUCTS_INDEX,
    eventName: CLICK_EVENT_NAMES[eventName],
    objectIDs,
  })

export const clickedObjectIDsAfterSearch = (
  objectIDs: string[],
  eventName: ClickEventName,
  positions: number[],
  queryID: string,
) =>
  insightsClient('clickedObjectIDsAfterSearch', {
    index: PRODUCTS_INDEX,
    eventName: CLICK_EVENT_NAMES[eventName],
    objectIDs,
    positions,
    queryID,
  })

export const convertedProductIDs = (objectIDs: string[], eventName: ConversionEventName) =>
  insightsClient('convertedObjectIDs', {
    index: PRODUCTS_INDEX,
    eventName: CONVERSION_EVENT_NAMES[eventName],
    objectIDs,
  })

export const convertedObjectIDsAfterSearch = (
  objectIDs: string[],
  eventName: ConversionEventName,
  queryID: string,
) =>
  insightsClient('convertedObjectIDsAfterSearch', {
    index: PRODUCTS_INDEX,
    eventName: CONVERSION_EVENT_NAMES[eventName],
    objectIDs,
    queryID,
  })
