import { useMemo } from 'react'
import { Box, BoxProps } from 'ui/core'

export interface MapEmbedProps extends BoxProps {
  mode: 'place' | 'directions',
  q?: string,
  origin?: string,
  destination?: string,
  testID?: string,
}

const MapEmbed = ({ mode, q, origin, destination, testID, ...rest }: MapEmbedProps) => {
  const src = useMemo(() => {
    const param = Object.entries({
      q,
      origin,
      destination,
      key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    })
      .filter(([, val]) => val)
      .map(([key, val]) => `${key}=${val}`)
      .join('&')
    return `https://www.google.com/maps/embed/v1/${mode}?${param}`
  }, [mode, q, origin, destination])

  return (
    <Box
      w="100%"
      h="100%"
      {...rest}
      component="iframe"
      loading="lazy"
      allowFullScreen
      bg="gray"
      style={{ border: 0, borderRadius: 8 }}
      src={src}
      data-testid={testID}
    />
  )
}

export default MapEmbed
