import { Select, SelectProps } from 'ui/core'
import { useSortBy as useISSortBy } from 'react-instantsearch-core'

export const useSortBy = () => {
  const sortBy = useISSortBy({
    items: [
      { label: 'Relevance', value: 'products' },
      { label: 'Price (asc)', value: 'products_price_asc' },
      { label: 'Price (desc)', value: 'products_price_desc' },
    ],
  })
  return sortBy
}

const SortBy = (props: Partial<SelectProps>) => {
  const sortBy = useSortBy()
  return (
    <Select
      {...props}
      label="Sort by"
      size="sm"
      value={sortBy.currentRefinement}
      data={sortBy.options}
      styles={{
        root: { display: 'flex', alignItems: 'center' },
        wrapper: { maxWidth: '128px', marginLeft: '8px' },
        label: { marginBottom: '0px' },
      }}
      onChange={(val) => sortBy.refine(val || sortBy.options[0].value)}
    />
  )
}

export default SortBy
