import { Box, BoxProps, createPolymorphicComponent } from 'ui/core'
import { CartItem } from 'lib/cart/types'
import { useCart } from 'lib/cart'
import { trackProducts } from 'lib/analytics'
import { MouseEventHandler, forwardRef } from 'react'

interface CartItemRemoveButtonProps extends BoxProps {
  item: CartItem,
  onClick?: MouseEventHandler<HTMLButtonElement>,
}

const CartItemRemoveButton = forwardRef<HTMLButtonElement, CartItemRemoveButtonProps>(
  ({ item, onClick, ...rest }, ref) => {
    const cart = useCart()

    return (
      <Box
        color="red"
        title="Remove"
        component="button"
        {...rest}
        ref={ref}
        onClick={(...args) => {
          cart.removeItem(item)
          trackProducts('remove_from_cart', {
            products: [
              {
                ...item.product,
                price: item.price,
                quantity: item.quantity,
                variant: item.variants?.map((variant) => variant.value).join(', '),
              },
            ],
          })
          onClick?.(...args)
        }}
      />
    )
  },
)

export default createPolymorphicComponent<'button', CartItemRemoveButtonProps>(CartItemRemoveButton)
