import { NextSeo, NextSeoProps } from 'next-seo'
import { siteMetadata } from 'core/utils'

export interface SeoProps extends NextSeoProps {}

const Seo = ({ description, title, openGraph, ...rest }: SeoProps) => {
  const siteName = siteMetadata.name
  const metaTitle = title || siteMetadata.title
  const metaDescription = description || siteMetadata.description

  return (
    <NextSeo
      {...rest}
      title={metaTitle}
      defaultTitle={siteMetadata.title}
      titleTemplate={`${siteName} - %s`}
      description={metaDescription}
      openGraph={{
        title: metaTitle,
        description: metaDescription,
        ...openGraph,
      }}
    />
  )
}

export default Seo
