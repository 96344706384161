import { Order } from 'model'
import useTask, { TaskState } from '@resellam/hooks/hooks/use-task'
import { useCreateDocument, useUpdateDocument } from '@resellam/firebase'
import { getAuth } from 'firebase/auth'
import { generateReference } from 'helpers/paystack'
import usePayOrder from './use-pay-order'

interface CreateOrder extends Required<Pick<Order, 'payment' | 'items'>>, Pick<Order, 'shipping'> {
  onCreate: (order: Order) => void,
  requiresPayment: boolean,
}

export interface CreateOrderResult {
  order: Order,
  paymentFailed: boolean,
}

const useCreateOrder = (): [
  (createOrder: CreateOrder) => Promise<CreateOrderResult | null>,
  TaskState<CreateOrderResult>,
] => {
  const [payOrder] = usePayOrder()
  const [createOrderFn] = useCreateDocument<Order>()
  const [updateOrderFn] = useUpdateDocument<Order>()
  const [taskFn, taskState] = useTask<CreateOrderResult>()

  const run = (createOrder: CreateOrder) =>
    taskFn(async () => {
      const user = getAuth().currentUser

      if (!user)
        throw new Error('Unauthenticated')

      const order = await createOrderFn({
        collection: 'orders',
        data: {
          status: 'DRAFT',
          payment: {
            ...createOrder.payment,
            paystack: createOrder.requiresPayment
              ? {
                  reference: generateReference(),
                }
              : undefined,
          },
          shipping: createOrder.shipping,
          items: createOrder.items,
        },
      })

      if (!order)
        throw new Error('Failed to create order')

      createOrder.onCreate(order)

      let paymentFailed = false

      if (createOrder.requiresPayment) {
        const transaction = await payOrder({
          order,
        })
        paymentFailed = !transaction?.reference
        if (!paymentFailed) {
          await updateOrderFn({
            collection: 'orders',
            id: order.id,
            data: { status: 'PLACED' },
          }).catch(() => {})
        }
      }

      return { order, paymentFailed }
    })

  return [run, taskState]
}

export default useCreateOrder
