import { useHits, useInstantSearch } from 'react-instantsearch-core'
import { AlgoliaProductHit } from 'lib/algolia'
import { NoResults } from 'core/components'
import { Button, Flex } from 'ui/core'
import { ReactNode, useState } from 'react'
import { useDidUpdate } from 'ui/hooks'
import { Catalogue, ProductCardLoading } from 'components'
import { setQueryID } from 'lib/search'

export type HitsProps = {
  renderHit: (params: { hit: AlgoliaProductHit, sendEvent: ReturnType<typeof useHits>['sendEvent'] }) => ReactNode,
}

const Hits = ({ renderHit }: HitsProps) => {
  const hits = useHits<AlgoliaProductHit>()
  const instantSearch = useInstantSearch()
  const [ready, setReady] = useState(false)

  const { status } = instantSearch
  const loading = ready ? status === 'stalled' : status === 'loading'

  useDidUpdate(() => {
    setQueryID(hits.results?.queryID)
  }, [hits.results?.queryID])

  useDidUpdate(() => {
    if (!ready && (status === 'idle' || status !== 'loading'))
      setReady(true)
  }, [status])

  const clearFilters = () => {
    instantSearch.setIndexUiState({ query: undefined })
  }

  if (
    !instantSearch.results.__isArtificial
    && instantSearch.results.nbHits === 0
  ) {
    return (
      <Flex direction="column" align="center">
        <NoResults query={instantSearch.indexUiState.query as string} />
        <Button variant="outline" onClick={clearFilters}>
          Clear filters
        </Button>
      </Flex>
    )
  }

  return (
    <Catalogue>
      {loading
        ? [...new Array(12).keys()].map((i) => (
            <ProductCardLoading imageWidth={180} imageHeight={180} key={i} />
          ))
        : hits.items.map((hit) => renderHit({ hit, sendEvent: hits.sendEvent }))}
    </Catalogue>
  )
}

export default Hits
