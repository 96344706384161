import { Dropzone } from 'core/components'
import { useUploadSellRequestPhotos } from '@resellam/sell-request'
import { ReactNode } from 'react'
import { BoxProps } from 'ui/core'

export interface UploadReceiptProps extends BoxProps {
  children: ReactNode,
  testID?: string,
}

const UploadReceipt = ({ testID, children, ...rest }: UploadReceiptProps) => {
  const uploadSellRequestPhotos = useUploadSellRequestPhotos()
  return (
    <Dropzone
      {...rest}
      unstyled
      multiple={false}
      style={{ flexGrow: 1 }}
      loading={uploadSellRequestPhotos.uploadReceiptState.isRunning}
      onDrop={(files) => uploadSellRequestPhotos.uploadReceipt(files)}
      testID={testID}
    >
      {children}
    </Dropzone>
  )
}

export default UploadReceipt
