import type { ReactNode } from 'react'
import { SettingsLayout as CoreSettingsLayout } from 'core/components'
import { Icon } from 'ui/core'
import { useFeatureFlag } from 'core/services'
import AppLayout from '../AppLayout'

export interface SettingsLayoutProps {
  children: ReactNode,
}

const SettingsLayout = ({ children }: SettingsLayoutProps) => {
  const featureFlag = useFeatureFlag()

  const navLinks = [
    {
      route: '/settings/profile',
      label: 'Profile',
      icon: <Icon name="userCircle" />,
    },
  ]

  if (featureFlag.value.payments) {
    navLinks.push({
      route: '/settings/payment',
      label: 'Payment',
      icon: <Icon name="creditCard" />,
    })
  }

  navLinks.push({
    route: '/settings/account',
    label: 'Account',
    icon: <Icon name="settings" />,
  })

  return (
    <AppLayout>
      <CoreSettingsLayout navLinks={navLinks}>{children}</CoreSettingsLayout>
    </AppLayout>
  )
}

export default SettingsLayout
