import { calcDiscountedPrice } from 'core/utils'
import { useMemo } from 'react'
import { useCartContext } from './CartProvider'
import { CartItem } from './types'

export interface UseCartTotalProps {
  checkout?: boolean,
}

const useCartTotal = (options?: UseCartTotalProps) => {
  const { items } = useCartContext()

  return useMemo(() => {
    const quantity = items
      .filter((item) => (options?.checkout ? item.checkout : true))
      .reduce((sum: number, item: CartItem) => sum + item.quantity, 0)

    const price = items
      .filter((item) => (options?.checkout ? item.checkout : true))
      .reduce(
        (sum: number, item: CartItem) =>
          sum + (calcDiscountedPrice(item.price, item.discount) || item.price) * item.quantity,
        0,
      )

    return {
      quantity,
      price,
    }
  }, [items, options])
}

export default useCartTotal
