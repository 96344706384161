import { PRODUCT_FIELDS, getSanityClient } from '@resellam/sanity'
import { fetch, filter, order, slice } from '@aonawale/sanity-query'
import { apiFetcher } from 'core/hooks'
import { calcVariantPricing, getVariantsMapping } from 'core/utils/product'
import { Product } from 'model'
import useSWRMutation from 'swr/mutation'

interface EstimatedProductPrice {
  product: Product,
  variants?: { label: string, value: string }[],
  batteryHealth?: number,
  swap?: {
    product: Product,
    variants?: { label: string, value: string }[],
  },
}

const calcPercentage = (value: number, total: number) => (value / 100) * total

const calcPrice = ({
  product,
  variants,
  batteryHealth,
  percentage,
}: EstimatedProductPrice & { percentage: number }) => {
  if (!product.price)
    return null

  const variantsMapping = getVariantsMapping(product)

  const basePrice = calcPercentage(percentage, product.price)
  const batteryHealthPrice
    = batteryHealth && batteryHealth > 80 ? calcPercentage(0.05, product.price) * batteryHealth : 0
  const variantPrice
    = variants?.reduce((acc, cur) => acc + (variantsMapping?.[cur.label]?.[cur.value] || 0), 0) || 0

  return Math.ceil(basePrice + batteryHealthPrice + variantPrice)
}

const getProductPrice = async ({ product, swap, ...rest }: EstimatedProductPrice) => {
  if (product.price)
    return calcPrice({ product, ...rest, percentage: 75 })

  const products = await fetch<Product[]>(
    getSanityClient(),
    {
      constraints: [
        filter('_type', '==', 'product'),
        filter('title', '==', product.title),
        filter('condition', 'in', ['UK Used', 'Naija Used']),
      ],
    },
    PRODUCT_FIELDS,
  )

  const ukUsed = products.find(({ condition }) => condition === 'UK Used')
  if (ukUsed?.price)
    return calcPrice({ ...rest, product: ukUsed, percentage: 85 })

  const naijaUsed = products.find(({ condition }) => condition === 'Naija Used')
  if (naijaUsed?.price)
    return calcPrice({ ...rest, product: naijaUsed, percentage: 90 })

  const result = await apiFetcher(`/api/products/estimate-price?product_id=${product.id}`)
  if (result?.data?.prices?.length)
    return Math.max(...result.data.prices)

  return null
}

const estimateProductPrice = async (_: string, { arg }: { arg: EstimatedProductPrice }) => {
  const { product, swap, ...rest } = arg
  const estimatedProductPrice = await getProductPrice({ product, swap, ...rest })

  if (swap?.product) {
    let swapProduct = swap.product.price ? swap.product : undefined

    if (!swapProduct) {
      const products = await fetch<Product[]>(
        getSanityClient(),
        {
          constraints: [
            filter('_type', '==', 'product'),
            filter('title', '==', product.title),
            filter('price', '>', 0),
            filter('inStock', '==', true),
            filter('condition', 'in', ['UK Used']),
            slice(0, 1),
            order('title', 'asc'),
            order('condition', 'asc'),
          ],
          ordering: 'selection',
        },
        PRODUCT_FIELDS,
      )
      swapProduct = products[0]
    }

    if (estimatedProductPrice && swapProduct?.price) {
      const variantPricing = calcVariantPricing(swapProduct, swap.variants || [])
      const swapProductPrice = (swapProduct.price || 0) + variantPricing.price
      return swapProductPrice - estimatedProductPrice
    }

    return null
  }

  return estimatedProductPrice
}

const useEstimateProductPrice = () => useSWRMutation(
  '/estimate-product-price',
  estimateProductPrice,
)

export default useEstimateProductPrice
