import { Title, Text, Group, useMantineTheme, Container, Box } from 'ui/core'
import { ProductSearch } from 'components'
import { WELCOME_PAGE_PRODUCTS_SEARCH_ID } from 'helpers/constants'
import { Image } from 'core/components'
import { useDynamicContent } from './useDynamicContent'
import classes from './Welcome.module.css'
import { useShowSearch } from 'components/AppLayout/AppLayout'

const Welcome = () => {
  const theme = useMantineTheme()
  const dynamicContent = useDynamicContent()
  const showSearch = useShowSearch()

  return (
    <Box className={classes.root}>
      <Container>
        <Group
          wrap="nowrap"
          mb="md"
          gap="md"
          justify="space-between"
        >
          <Box className={classes.leftSection}>
            <Title order={2}>Buy, Sell, Swap</Title>
            <Title order={1} mt="xs" className={classes.dynamixText}>
              <span style={{ color: theme.colors.blue[5] }}>
                {dynamicContent.text}
              </span>
            </Title>
            <Text mt="xs" className={classes.description}>
              Get paid instantly for your used or new phones, laptops, and
              gadgets from the comfort of your home
              {/* New, UK Used, Naija Used Gadgets and get paid instantly from the comfort of your home */}
            </Text>
          </Box>
          <Box className={classes.dynamicImage} visibleFrom="sm">
            <Image
              priority
              fill
              quality={100}
              alt="welcome image"
              src={dynamicContent.imageUrl}
            />
          </Box>
        </Group>
        <div id={WELCOME_PAGE_PRODUCTS_SEARCH_ID}>
          {!showSearch && (
            <ProductSearch
              triggerProps={{
                size: 'lg',
              }}
            />
          )}
        </div>
      </Container>
    </Box>
  )
}

export default Welcome
