import { AlgoliaProductHit } from 'lib/algolia'
import { SearchType } from './types'

export const getProductURL = (type: SearchType | undefined, product: AlgoliaProductHit) => {
  switch (type) {
    case 'Buy':
      return `/shop?${new URLSearchParams(`q=${product.title}`)}`
    case 'Sell':
      return `/sell/${product.slug}`
    case 'Swap':
      return `/swap/${product.slug}`
    default:
      return `/shop?${new URLSearchParams(`q=${product.title}`)}`
  }
}
