import { Flex, Text } from 'ui/core'
import { ReactNode } from 'react'
import { formatTestID } from 'core/utils'

interface SellRequestCountdownTimeProps {
  label: string,
  value?: ReactNode,
  testID?: string,
}

const SellRequestCountdownTime = ({ label, value, testID }: SellRequestCountdownTimeProps) => (
  <Flex direction="column" align="center" data-testid={testID}>
    <Text fz={32} data-testid={formatTestID(testID, 'value')}>
      {value}
    </Text>
    <Text caption data-testid={formatTestID(testID, 'label')}>
      {label}
    </Text>
  </Flex>
)

export default SellRequestCountdownTime
