import { Flex, IconName } from 'ui/core'
import { useSellRequest } from '@resellam/sell-request'
import { formatTestID } from 'core/utils'
import { useSwapOffers } from 'lib/sell-request'
import { SellRequestOffer, SellRequestOfferLoading } from '../SellRequestOffer'
import { SellRequestSelectedOfferData } from '../SellRequestSelectedOffer'
import { useSellRequestFrontImage } from '../SellRequestStatus.utils'

const swapOfferHeaderProps = {
  title: 'Swap',
  iconName: 'repeat' as IconName,
}

interface SellRequestSwapOffersProps {
  onSelecctedOffer: (offer: SellRequestSelectedOfferData | null) => void,
  testID?: string,
}

const SellRequestSwapOffers = ({ onSelecctedOffer, testID }: SellRequestSwapOffersProps) => {
  const { sellRequest } = useSellRequest()
  const { swapOffers, isLoading } = useSwapOffers()
  const { offer, product, variants, upgrade } = sellRequest || {}
  const sellRequestImage = useSellRequestFrontImage()

  if (!offer)
    return null

  return (
    <Flex
      gap="md"
      justify="center"
      align={{ base: 'center', sm: 'initial' }}
      direction={{ base: 'column', sm: 'row' }}
    >
      <SellRequestOffer
        headerProps={{
          title: 'Sell',
          iconName: 'cash',
        }}
        detailProps={{
          price: offer.price,
          product,
          variants,
          condition: 'Your Device',
          imageProps: { path: sellRequestImage, alt: 'Your Device' },
        }}
        accept={{
          children: 'Sell',
          onClick: () =>
            onSelecctedOffer({
              price: offer.price,
            }),
        }}
        testID={formatTestID(testID, 'sell-offer')}
      />
      {upgrade && (
        <>
          {isLoading ? (
            <>
              <SellRequestOfferLoading
                {...swapOfferHeaderProps}
                accept={{
                  children: 'Swap UK Used',
                }}
              />
              <SellRequestOfferLoading
                {...swapOfferHeaderProps}
                accept={{
                  children: 'Swap New',
                }}
              />
            </>
          ) : (
            swapOffers.map((swapOffer) => (
              <SellRequestOffer
                key={swapOffer.product.id}
                headerProps={swapOfferHeaderProps}
                detailProps={{
                  ...swapOffer,
                  swap: true,
                  condition: swapOffer.product?.condition,
                }}
                accept={{
                  children: `Swap ${swapOffer.product?.condition}`,
                  onClick: () =>
                    onSelecctedOffer({
                      price: swapOffer.price,
                      swap: {
                        product: swapOffer.product,
                        variants: swapOffer.variants,
                      },
                    }),
                }}
                testID={formatTestID(testID, 'swap-offer', swapOffer.product.id)}
              />
            ))
          )}
        </>
      )}
    </Flex>
  )
}

export default SellRequestSwapOffers
