import { Stack, StackProps, Button, ButtonProps } from 'ui/core'
import { AgentInfo, Alert } from 'components'
import { formatTestID } from 'core/utils'

export interface SellRequestDropoffProps extends StackProps {
  testID?: string,
  confirm?: ButtonProps,
}

const SellRequestDropoff = ({ testID, confirm, ...rest }: SellRequestDropoffProps) => (
  <Stack {...rest} data-testid={testID}>
    <AgentInfo testID={formatTestID(testID, 'agent-info')} />
    <Alert
      alertStyle="warning"
      testID={formatTestID(testID, 'alert')}
    >
      You&rsquo;ll be asked to provide your Driver&apos;s license, Voters card, or International
      passport.
    </Alert>
    {confirm && (
      <Button
        fullWidth
        data-testid={formatTestID(testID, 'confirm-button')}
        {...confirm}
      />
    )}
  </Stack>
)

export default SellRequestDropoff
