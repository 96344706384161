import { AlgoliaProductHit, clickedObjectIDsAfterSearch, clickedProductIDs } from 'lib/algolia'
import { ProductCard } from 'components'
import analytics from 'lib/analytics'
import { getQueryID } from 'lib/search'

interface RelatedItemProps {
  item: AlgoliaProductHit,
  testID?: string,
}

const RelatedItem = ({ item, testID }: RelatedItemProps) => {
  return (
    <ProductCard
      testID={testID}
      href={`/shop/${item.slug}`}
      product={{ ...item, id: item.objectID, variants: [] }}
      onClick={() => {
        const queryID = getQueryID()
        if (queryID) {
          clickedObjectIDsAfterSearch(
            [item.objectID],
            'pdpRelatedProductClickedAfterSearch',
            [0],
            queryID,
          )
        } else {
          clickedProductIDs([item.objectID], 'pdpRelatedProductClicked')
        }
        analytics.track('shop_view_related_product', {
          category: 'engagement',
          product_name: item.title,
          product_brand_name: item.brand.title,
          product_category_name: item.category.title,
        })
      }}
    />
  )
}

export default RelatedItem
