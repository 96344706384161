import {
  TextInput,
  Loader,
  Text,
  Select,
  Flex,
} from 'ui/core'
import { useCreateBankAccount } from 'lib/bank-account'
import { Bank, BankAccount } from 'model'
import { useApi, useApiMutation, useNotifications, useFormUI } from 'core/hooks'
import { BankAccountResolve } from 'lib/paystack'
import { z } from 'zod'
import analytics from 'lib/analytics'
import { Alert } from 'components/Alert'

const schema = z.object({
  bankCode: z.string().nonempty('Select a bank'),
  accountNumber: z
    .string()
    .length(10, { message: 'Enter a 10 digit account number' }),
})

interface BankAccountFormValues {
  bankCode: string,
  accountNumber: string,
}

interface BankAccountFormProps {
  onCancel: () => void,
  onSave: (bankAccount: BankAccount) => void,
}

const BankAccountForm = ({ onCancel, onSave }: BankAccountFormProps) => {
  const notifications = useNotifications()
  const { data, isLoading } = useApi<{ data?: Bank[] }>('/banks')
  const [resolveBankAccount, resolveBankAccountState] = useApiMutation<{
    data?: BankAccountResolve,
  }>()
  const [createBankAccount, createBankAccountState] = useCreateBankAccount()

  const { form, render } = useFormUI<BankAccountFormValues>({
    schema,
    initialValues: {
      bankCode: '',
      accountNumber: '',
    },
    onValuesChange: (values) => {
      if (form.isValid()) {
        const { bankCode, accountNumber } = values
        resolveBankAccount({
          url: `/banks/resolve?account_number=${accountNumber}&bank_code=${bankCode}`,
        })
      }
    },
  })

  const banks = data?.data || []
  const resolvedBankAccount = resolveBankAccountState?.value?.data

  const handleSubmit = async (values: BankAccountFormValues) => {
    if (!resolvedBankAccount)
      return

    analytics.track('add_payout_info', {
      category: 'engagement',
      type: 'bank_account',
    })

    const bank = banks.find(({ code }) => code === values.bankCode)

    if (!bank)
      return

    const { accountNumber, accountName } = resolvedBankAccount

    const bankAccount = await createBankAccount({
      bank: {
        type: bank.type,
        longcode: bank.longcode,
        currency: bank.currency,
        country: bank.country,
        code: bank.code,
        name: bank.name,
        id: bank.id,
      },
      accountNumber,
      accountName,
    })
    notifications.show({
      variant: bankAccount ? 'success' : 'error',
      action: 'create',
      entity: 'payout method',
    })
    if (bankAccount) {
      onSave(bankAccount)
    }
  }

  return render({
    isLoading: createBankAccountState.isRunning,
    onSubmit: handleSubmit,
    onCancel,
    children: (
      <>
        <Select
          {...form.getInputProps('bankCode')}
          searchable
          label="Bank"
          loading={isLoading}
          data={banks.map((bank) => ({
            label: bank.name,
            value: bank.code,
          }))}
        />
        <TextInput
          {...form.getInputProps('accountNumber')}
          type="number"
          label="Account number"
        />
        {resolveBankAccountState.isRunning && (
          <Alert alertStyle="info">
            <Flex gap="md" align="center" justify="center">
              <Text>Validating account</Text> <Loader type="dots" />
            </Flex>
          </Alert>
        )}
        {resolveBankAccountState.isSuccess && !resolvedBankAccount && (
          <Alert alertStyle="error">
            <Flex justify="center">
              <Text color="red">Could not validate account</Text>
            </Flex>
          </Alert>
        )}
        {resolveBankAccountState.isSuccess && resolvedBankAccount && (
          <TextInput
            disabled
            variant="filled"
            label="Account name"
            value={resolvedBankAccount.accountName}
          />
        )}
      </>
    ),
  })
}

export default BankAccountForm
