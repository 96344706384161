import { Stack, Collapse, FormFieldProps, SegmentedControl, FormField } from 'ui/core'
import { ReactNode } from 'react'
import { formatTestID } from 'core/utils'

enum BooleanValue {
  NO = 'No',
  YES = 'Yes',
}

export interface BooleanFieldProps extends Omit<FormFieldProps, 'value' | 'onChange'> {
  disabled?: boolean,
  children: ReactNode,
  value?: boolean,
  onChange?: (value: boolean) => void,
  testID?: string,
}

const BooleanField = ({
  value,
  disabled,
  onChange,
  children,
  testID,
  size,
  ...rest
}: BooleanFieldProps) => {
  let radioValue = ''

  if (typeof value === 'boolean') {
    radioValue = value ? BooleanValue.YES : BooleanValue.NO
  }

  const change = (val: string) => {
    onChange?.(val === BooleanValue.YES)
  }

  return (
    <Stack gap="xs" data-testid={testID}>
      <FormField {...rest}>
        <SegmentedControl
          fullWidth
          size={size}
          disabled={disabled}
          value={radioValue}
          onChange={change}
          data={[BooleanValue.YES, BooleanValue.NO]}
          data-testid={formatTestID(testID, 'segmented-control')}
        />
      </FormField>
      <Collapse data-testid="collapse" in={!!value}>
        {value && children}
      </Collapse>
    </Stack>
  )
}

export default BooleanField
