import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { AlgoliaProductHit } from 'lib/algolia'
import analytics from 'lib/analytics'
import { Popover } from 'ui/core'
import { useBreakpoint } from 'core/hooks'
import { getProductURL } from './utils'
import { ProductSearchForm } from './ProductSearchForm'
import { useProductSearch } from './useProductSearch'
import {
  ProductSearchTrigger,
  ProductSearchTriggerProps,
} from './ProductSearchTrigger'

interface ProductSearchProps {
  withinPortal?: boolean,
  triggerProps?: Partial<ProductSearchTriggerProps>,
}

export const ProductSearch = ({
  withinPortal = true,
  triggerProps,
}: ProductSearchProps) => {
  const router = useRouter()
  const { autocomplete, searchType, setSearchType } = useProductSearch()
  const [isOpen, setIsOpen] = useState(false)
  const { isXs } = useBreakpoint()

  const openSearch = () => setIsOpen(true)

  const closeSearch = () => {
    setIsOpen(false)
    setSearchType(undefined)
  }

  const handleProductClick = (hit: AlgoliaProductHit) => {
    let eventName: string
    if (searchType === 'Swap') {
      eventName = 'view_swap_product'
    } else if (searchType === 'Buy') {
      eventName = 'view_shop_product'
    } else {
      eventName = 'view_product'
    }

    analytics.track(eventName, {
      category: 'engagement',
      product_name: hit.title,
      product_brand_name: hit.brand?.title,
      product_category_name: hit.category?.title,
    })

    const url = getProductURL(searchType, hit)
    const isShop = router.pathname === '/shop'

    if (isShop) {
      router.push(url, undefined, { shallow: true })
    } else {
      router.push(url)
    }

    closeSearch()
  }

  const popoverWidth
    = typeof window === 'undefined' ? 'target' : window.innerWidth - 32

  return (
    <Popover
      withinPortal={withinPortal}
      trapFocus
      position="bottom"
      shadow="md"
      opened={isOpen}
      onClose={closeSearch}
      onChange={setIsOpen}
      width={isXs ? popoverWidth : 'target'}
      withOverlay
    >
      <Popover.Target>
        <ProductSearchTrigger {...triggerProps} onTrigger={openSearch} />
      </Popover.Target>
      <Popover.Dropdown>
        <div {...autocomplete.getRootProps({})}>
          <ProductSearchForm
            onClickProduct={handleProductClick}
            onCancel={closeSearch}
          />
        </div>
      </Popover.Dropdown>
    </Popover>
  )
}
