import { LoadingOverlay, Box, Select, Stack } from 'ui/core'
import { zodResolver } from 'ui/form'
import { logger } from '@resellam/logger'
import { Money } from 'core/components'
import { Address } from 'model'
import { usePickupEstimate } from 'lib/errandlr'
import { useForm, useNotifications } from 'core/hooks'
import { z } from 'zod'
import analytics from 'lib/analytics'
import { addressSchema, formatTestID, getAgentForCategory } from 'core/utils'
import { useSellRequest } from '@resellam/sell-request'
import { Alert, StepNavButtons } from 'components'
import { useMemo, useState } from 'react'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { formatDeliveryDate } from '../../SellRequestStatus.utils'
import { AddressAutocomplete } from 'components/AddressAutocomplete'

dayjs.extend(isSameOrBefore)

type EstimateData = {
  date: Date,
  amount: number,
  address: Address,
}

interface SellRequestPickupEstimateProps {
  onNext: (data: EstimateData) => void,
  testID?: string,
}

const schema = ({ minDate, maxDate }: { minDate: Date, maxDate: Date }) =>
  z.object({
    date: z.date({ required_error: 'Select pickup date' }).min(minDate).max(maxDate),
    address: addressSchema({ required_error: 'Select address' }).extend({
      id: z.string(),
    }),
  })

const SellRequestPickupEstimate = ({ onNext, testID }: SellRequestPickupEstimateProps) => {
  const notifications = useNotifications()
  const { sellRequest } = useSellRequest()
  const [getPickupEstimate, getPickupEstimateState] = usePickupEstimate()
  const [estimateData, setEstimateData] = useState<EstimateData | null>(null)

  const minDate = dayjs()
    .add(dayjs().hour() < 17 ? 0 : 1, 'day')
    .startOf('day')

  const maxDate = (
    sellRequest?.offer?.expiresAt ? dayjs(sellRequest?.offer?.expiresAt) : dayjs().add(1, 'week')
  ).endOf('day')

  const selectDays = useMemo(() => {
    const options = []
    let currentDate = minDate.clone()
    while (currentDate.isSameOrBefore(maxDate)) {
      if (currentDate.day() !== 0) {
        options.push({
          label: formatDeliveryDate(currentDate),
          value: currentDate.toISOString(),
        })
      }
      currentDate = currentDate.add(1, 'day')
    }
    return options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minDate.toISOString(), maxDate.toISOString()])

  const form = useForm<{ date?: Date, address?: Address }>({
    initialValues: {},
    validate: zodResolver(
      schema({
        minDate: minDate.toDate(),
        maxDate: maxDate.toDate(),
      }),
    ),
  })

  const estimate = async (values: typeof form.values) => {
    if (form.validate().hasErrors)
      return

    analytics.track('get_sell_request_pickup_estimate', {
      category: 'ecommerce',
    })

    const category = sellRequest?.product?.category?.slug
    const { address, date } = values as Required<typeof form.values>

    const agent = getAgentForCategory(category)
    if (!address.googlePlaceId)
      throw new Error('Invalid address')

    const result = await getPickupEstimate({
      pickupLocation: {
        id: address.googlePlaceId,
        name: address.street,
      },
      dropoffLocation: {
        id: agent.location.googlePlaceId,
        name: agent.location.googlePlaceName,
      },
    })

    if (result?.data) {
      setEstimateData({
        date,
        address,
        amount: result?.data.estimate,
      })
    } else {
      logger.error(result, 'Failed to get estimate')
      notifications.show({
        variant: 'error',
        message: 'Failed to get estimate',
      })
    }
  }

  return (
    <Box
      component="form"
      pos="relative"
      onSubmit={form.onSubmit(estimate)}
      data-testid={testID}
    >
      <LoadingOverlay visible={getPickupEstimateState.isRunning} />
      <Stack>
        <Select
          required
          label="Date"
          {...form.getInputProps('date')}
          value={form.values.date?.toISOString()}
          onChange={(val) => {
            (val ? form.setFieldValue('date', new Date(val)) : null)
            setEstimateData(null)
          }}
          data={selectDays}
          data-testid={formatTestID(testID, 'date')}
        />
        <AddressAutocomplete
          {...form.getInputProps('address')}
          onChange={(val) => {
            form.setFieldValue('address', val)
            setEstimateData(null)
          }}
          required
          label="Address"
          testID={formatTestID(testID, 'address')}
        />
        {estimateData && (
          <Alert alertStyle="info" title="Estimated price" data-testid={formatTestID(testID, 'amount-label')}>
            <Money
              align="center"
              size="lg"
              fw="bold"
              value={estimateData.amount}
              data-testid={formatTestID(testID, 'amount-value')}
            />
          </Alert>
        )}
      </Stack>
      <StepNavButtons
        next={
          estimateData
            ? {
                onClick: () => onNext(estimateData),
              }
            : {
                type: 'submit',
                loading: getPickupEstimateState.isRunning,
                children: 'Check price',
              }
        }
        testID={formatTestID(testID, 'step-nav')}
      />
    </Box>
  )
}

export default SellRequestPickupEstimate
