import { Button, Stack } from 'ui/core'
import { useCart } from 'lib/cart'
import { Money } from 'core/components'
import Link from 'next/link'

const FloatingCartHeader = () => {
  const cart = useCart({ checkout: true })

  return (
    <Stack gap="xs" align="center">
      <Money
        value={cart.total.price}
        inline
        fw="bold"
        align="center"
        size="md"
      />
      <Button
        component={Link}
        href="/shop/cart"
        size="compact-md"
        variant="light"
        onClick={() => cart.close()}
      >
        Checkout
      </Button>
    </Stack>
  )
}

export default FloatingCartHeader
