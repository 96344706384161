import { Skeleton, Card, Group } from 'ui/core'

const ActivityCardLoading = () => (
  <Card>
    <Group justify="space-between">
      <Skeleton height={20} width="30%" radius="xl" />
      <Skeleton height={20} width="15%" radius="xl" />
    </Group>
  </Card>
)

export default ActivityCardLoading
