import { Flex, FlexProps, Group, Icon, Text } from 'ui/core'
import { Image, ImageProps, Money } from 'core/components'
import { formatTestID, formatVariantValue } from 'core/utils'
import { Product, ProductCondition as ProductConditionModel, SellRequestVariant } from 'model'
import { ProductCondition } from 'components'

export interface SellRequestOfferDetailsProps extends FlexProps {
  imageProps?: ImageProps,
  product?: Product,
  price?: number,
  swap?: boolean,
  condition?: ProductConditionModel | string,
  variants?: SellRequestVariant[],
  testID?: string,
}

const SellRequestOfferDetails = ({
  imageProps,
  product,
  condition,
  price,
  swap,
  variants,
  testID,
  ...rest
}: SellRequestOfferDetailsProps) => (
  <Flex
    gap="md"
    direction="column"
    align="center"
    data-testid={testID}
    {...rest}
  >
    <Image
      {...imageProps}
      priority
      withLoader
      src={product?.image}
      alt={imageProps?.alt || product?.title || ''}
      height={imageProps?.height || 280}
      width={imageProps?.width || 280}
      isLoading={!product}
      testID={formatTestID(testID, 'image')}
    />
    <Flex gap="xs" direction="column" align="center">
      {condition && (
        <ProductCondition
          condition={condition as ProductConditionModel}
          testID={formatTestID(testID, 'condition')}
        />
      )}
      <div>
        <Text align="center" data-testid={formatTestID(testID, 'title')}>
          {product?.title}
        </Text>
        {variants?.map((variant) => (
          <Text
            align="center"
            key={variant.label}
            data-testid={formatTestID(testID, 'variant', variant.label)}
          >
            {formatVariantValue(variant.value)}
          </Text>
        ))}
      </div>
      {price && (
        <Group gap={4} align="center">
          {swap && (
            <>
              <Text inline size="lg" fw="bold">
                Your Device
              </Text>
              <Icon name="plus" thickness={2} />
            </>
          )}
          <Money
            inline
            value={price}
            fw="bold"
            size="lg"
            data-testid={formatTestID(testID, 'price')}
          />
        </Group>
      )}
    </Flex>
  </Flex>
)

export default SellRequestOfferDetails
