import { Flex, PaperProps, Text } from 'ui/core'
import { EmptyPhotoCard, Dropzone } from 'core/components'

export interface UploadPhotoCardProps extends Omit<PaperProps, 'children' | 'onChange'> {
  label?: string,
  onChange: (file: File) => void,
  height?: number,
  error?: boolean,
  testID?: string,
}

const UploadPhotoCard = ({ label, error, onChange, height, testID }: UploadPhotoCardProps) => (
  <EmptyPhotoCard
    style={(theme) => ({ borderColor: error ? theme.colors.red[5] : undefined, borderRadius: 0 })}
    testID={testID}
    component={Dropzone}
    unstyled
    multiple={false}
    onDrop={([file]: File[]) => onChange(file)}
  >
    <Flex
      h={height}
      style={{ width: '100%' }}
      justify="center"
      align="center"
    >
      <Text size="xl" align="center">
        {label}
      </Text>
    </Flex>
  </EmptyPhotoCard>
)

export default UploadPhotoCard
