import { RowCarousel, SectionTitle } from 'components'
import { SwapOffer } from 'model'
import { SwapOfferCard } from './SwapOfferCard'

interface SwapOffersProps {
  swapOffers: SwapOffer[],
}

const SwapOffers = ({ swapOffers }: SwapOffersProps) => (
  <div>
    <SectionTitle title="Swap offers" />
    <RowCarousel slideSize={{ base: '50%' }} items={swapOffers}>
      {(swapOffer) => (
        <SwapOfferCard key={swapOffer.id} swapOffer={swapOffer} />
      )}
    </RowCarousel>
  </div>
)
export default SwapOffers
