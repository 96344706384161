import { forwardRef, useMemo, useState } from 'react'
import {
  useCombobox,
  Combobox,
  Loader,
  Icon,
  TextInput,
  ComboboxProps,
  TextInputProps,
} from 'ui/core'
import { AlgoliaProductHit } from 'lib/algolia'
import { useProductSearch } from '../useProductSearch'

interface ProductAutocompleteProps
  extends Omit<ComboboxProps, 'onChange'>,
  Pick<TextInputProps, 'labelProps' | 'placeholder' | 'value' | 'label' | 'onInput' | 'error'> {
  onChange?: (product: AlgoliaProductHit | null) => void,
  testID?: string,
}

const ProductAutocomplete = forwardRef<HTMLInputElement, ProductAutocompleteProps>(
  ({ value: initialValue, size, placeholder, labelProps, onChange, testID, error, ...rest }, ref) => {
    const [value, setValue] = useState(initialValue || '')

    const combobox = useCombobox({
      onDropdownClose: () => combobox.resetSelectedOption(),
    })

    const { autocomplete, status, products } = useProductSearch()
    const loading = status === 'loading'

    const items = products?.items
    const data = useMemo(() => items?.slice(0, 5) ?? [], [items])

    const empty = !loading && !data.length && value

    const options = (data || []).map((item) => (
      <Combobox.Option value={item.title} key={item.objectID}>
        {item.title}
      </Combobox.Option>
    ))

    const fetchOptions = (query: string) => {
      autocomplete.setQuery(query)
      autocomplete.refresh()
    }

    return (
      <Combobox
        withinPortal
        data-lpignore="true"
        data-testid={testID}
        size={size}
        {...rest}
        // @ts-expect-error
        ref={ref}
        onOptionSubmit={(optionValue) => {
          const product = data.find((item) => item.title === optionValue)
          if (product) {
            setValue(optionValue)
            onChange?.(product)
          }
          combobox.closeDropdown()
        }}
        store={combobox}
      >
        <Combobox.Target>
          <TextInput
            size={size}
            error={error}
            value={value}
            labelProps={labelProps}
            placeholder={placeholder}
            leftSection={<Icon name="search" />}
            onChange={(event) => {
              setValue(event.currentTarget.value)
              fetchOptions(event.currentTarget.value)
              combobox.resetSelectedOption()
              combobox.openDropdown()
            }}
            onClick={() => combobox.openDropdown()}
            onFocus={() => combobox.openDropdown()}
            onBlur={() => combobox.closeDropdown()}
            rightSection={loading && <Loader size="sm" />}
          />
        </Combobox.Target>

        <Combobox.Dropdown hidden={!(Boolean(data.length) || empty)}>
          <Combobox.Options>
            {options}
            {empty && <Combobox.Empty>No results found</Combobox.Empty>}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    )
  },
)

export default ProductAutocomplete
