import { AuthForm as CoreAuthForm } from 'core/components'
import { useNotifications } from 'core/hooks'
import analytics from 'lib/analytics'

interface AuthFormProps {
  onSuccess?: () => void,
}

const AuthForm = ({ onSuccess }: AuthFormProps) => {
  const notifications = useNotifications()

  return (
    <CoreAuthForm
      variant="user"
      onStart={(provider) => {
        analytics.track('login', {
          provider,
          category: 'engagement',
        })
      }}
      onSuccess={() => {
        notifications.show({
          variant: 'success',
          message: 'Successfully logged in',
        })
        onSuccess?.()
      }}
    />
  )
}

export default AuthForm
