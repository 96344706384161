import { RowCarousel } from 'components'
import { useCart } from 'lib/cart'
import { getCartItemID } from 'helpers/shop/shop'
import { SectionTitle } from '../../SectionTitle'
import { MyCartItem } from '../MyCartItem'

const MyCart = () => {
  const cart = useCart()

  if (!cart.items.length)
    return null

  return (
    <div>
      <SectionTitle title="My cart" rightAction={{ title: 'View cart', href: '/shop/cart' }} />
      <RowCarousel items={cart.items}>
        {(item) => <MyCartItem key={getCartItemID(item)} item={item} />}
      </RowCarousel>
    </div>
  )
}

export default MyCart
