import { Product } from 'model'
import { InstantSearch, useRelatedProducts } from 'react-instantsearch-core'
import { AlgoliaProductHit, algoliaSearchClient, PRODUCTS_INDEX } from 'lib/algolia'
import { RowCarousel } from 'components'
import { SectionHeader } from 'core/components'
import { formatTestID } from 'core/utils'
import { RelatedItem } from './RelatedItem'

interface RelatedProductsProps {
  product: Product,
  testID?: string,
}

const RelatedProducts = ({ product, testID }: RelatedProductsProps) => {
  const { items } = useRelatedProducts<AlgoliaProductHit>({
    objectIDs: [product.id],
    limit: 10,
    queryParameters: {
      filters: `inStock:true`,
      numericFilters: `price>0`,
      facetFilters: [
        `brand.title:${product.brand?.title}`,
        `category.title:${product.category?.title}`,
      ],
    },
    fallbackParameters: {
      filters: `inStock:true`,
      numericFilters: 'price>0',
      facetFilters: [`category.title:${product.category?.title}`],
    },
  })

  if (!items.length)
    return null

  return (
    <div data-testid={testID}>
      <SectionHeader title="Related products" testID={formatTestID(testID, 'header')} />
      <RowCarousel
        key={product.id}
        items={items}
        testID={formatTestID(testID, 'carousel')}
      >
        {(item) => (
          <RelatedItem item={item} testID={formatTestID(testID, `related-item-${item.objectID}`)} />
        )}
      </RowCarousel>
    </div>
  )
}

const RelatedProductsWithSearch = ({ product, testID }: RelatedProductsProps) => {
  return (
    <InstantSearch searchClient={algoliaSearchClient} indexName={PRODUCTS_INDEX}>
      <RelatedProducts product={product} testID={testID} />
    </InstantSearch>
  )
}

export default RelatedProductsWithSearch
