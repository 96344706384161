import { useAuth as useCoreAuth } from '@resellam/auth'
import { AuthForm } from 'components'
import { randomId } from 'ui/hooks'
import { modals } from 'ui/modals'

const useAuth = () => {
  const auth = useCoreAuth()

  const authenticate = () =>
    new Promise<void>((resolve) => {
      const modalId = randomId()
      modals.open({
        modalId,
        title: 'Get Started',
        closeOnClickOutside: false,
        closeOnEscape: false,
        withCloseButton: true,
        children: (
          <AuthForm
            onSuccess={() => {
              modals.close(modalId)
              resolve()
            }}
          />
        ),
      })
    })

  const requireAuth = async () => {
    if (!auth.user) {
      await authenticate()
    }
  }

  return {
    ...auth,
    requireAuth,
  }
}

export default useAuth
