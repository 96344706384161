import { PRODUCT_PART_FIELDS, getSanityClient } from '@resellam/sanity'
import { Product, ProductPart, SellRequestIssue } from 'model'
import {
  Loader,
  MultiSelect,
  MultiSelectProps,
  Text,
  Flex,
  Icon,
  OptionsFilter,
  ComboboxItem,
} from 'ui/core'
import { useState } from 'react'
import { useQueryDocuments } from '@aonawale/react-sanity-hooks'
import { filter, order } from '@aonawale/sanity-query'
import { Image } from 'core/components'
import { formatTestID } from 'ui/utils'

const Option = ({ productPart, testID }: any) => {
  return (
    <Flex align="center" gap="sm" data-testid={testID}>
      {productPart.image && (
        <Image
          unoptimized
          src={productPart.image}
          alt={productPart.name}
          width={40}
          height={40}
        />
      )}
      <Text align="center">{productPart.name}</Text>
    </Flex>
  )
}

const ADD_NEW = 'ADD_NEW'

interface ProductPartsFieldProps
  extends Omit<MultiSelectProps, 'value' | 'onChange'> {
  product?: Product,
  value?: SellRequestIssue[],
  onChange?: (value: SellRequestIssue[]) => void,
  testID?: string,
}

const ProductPartsField = ({
  value,
  product,
  onChange,
  testID,
  ...rest
}: ProductPartsFieldProps) => {
  const { data: parts, isLoading } = useQueryDocuments<ProductPart>(
    getSanityClient(),
    product?.category?.id
      ? {
          type: 'productPart',
          constraints: [
            filter(
              `references(*[_type=="category" && _id == "${product?.category?.id}"]._id)`,
            ),
            order('name', 'asc'),
          ],
        }
      : undefined,
    PRODUCT_PART_FIELDS,
  )

  const [searchValue, setSearchValue] = useState('')
  const [localParts, setLocalParts] = useState<ProductPart[]>([])

  const partsMap = new Map(
    localParts.concat(parts ?? []).map((part) => [part.id, part]),
  )

  const data = [
    ...localParts.concat(parts ?? []).map((part) => ({
      value: part.id,
      label: part.name,
    })),
    ...(searchValue ? [{ value: ADD_NEW, label: 'Create new part' }] : []),
  ]

  const optionsFilter: OptionsFilter = ({ options, search }) => {
    const splittedSearch = search.toLowerCase().trim().split(' ')
    return (options as ComboboxItem[]).filter((option) => {
      if (option.value === ADD_NEW)
        return true
      const words = option.label.toLowerCase().trim().split(' ')
      return splittedSearch.every((searchWord) =>
        words.some((word) => word.includes(searchWord)),
      )
    })
  }

  return (
    <MultiSelect
      label="Parts"
      {...rest}
      multiple
      value={value?.map(({ part }) => part.id)}
      onChange={(data) => {
        const newPart = data.includes(ADD_NEW)
          ? { id: searchValue, name: searchValue }
          : undefined
        const values: SellRequestIssue[] = data
          .filter((id) => id !== ADD_NEW)
          .map((id) => ({ part: partsMap.get(id) as ProductPart }))

        onChange?.([...values, ...(newPart ? [{ part: newPart }] : [])])
      }}
      onOptionSubmit={(value) => {
        if (value === ADD_NEW) {
          setLocalParts([
            ...localParts,
            { id: searchValue, name: searchValue },
          ])
          setSearchValue('')
        }
      }}
      searchable
      hidePickedOptions
      data-testid={testID}
      data-lpignore="true"
      searchValue={searchValue}
      comboboxProps={{ withinPortal: true }}
      leftSection={isLoading ? <Loader size="sm" /> : undefined}
      data={data}
      onSearchChange={(value) => setSearchValue(value)}
      renderOption={({ option }) =>
        option.value === ADD_NEW ? (
          <Flex w="100%" gap="sm" align="center">
            <Icon name="plus" />
            <Text>Create "{searchValue}"</Text>
          </Flex>
        ) : (
          <Option productPart={partsMap.get(option.value)} testID={formatTestID(testID, option.value)} />
        )}
      filter={optionsFilter}
    />
  )
}

export default ProductPartsField
