import { Radio, Stack } from 'ui/core'
import { useNumericMenu } from 'react-instantsearch-core'
import { formatMoney } from 'core/utils'

export const usePriceMenu = () => {
  const numericMenu = useNumericMenu({
    attribute: 'price',
    items: [
      { label: `All` },
      { label: `${formatMoney(20000)} - ${formatMoney(50000)}`, start: 20000, end: 50000 },
      { label: `${formatMoney(50000)} - ${formatMoney(100000)}`, start: 50000, end: 100000 },
      { label: `More than ${formatMoney(100000)}`, start: 100000 },
    ],
  })

  return numericMenu
}

const PriceMenu = () => {
  const numericMenu = usePriceMenu()

  const refined = numericMenu.items.find((item) => item.isRefined)

  return (
    <Radio.Group
      name="price"
      value={refined?.value}
      onChange={(val) => numericMenu.refine(val)}
    >
      <Stack gap="xs">
        {numericMenu.items.map((item) => (
          <Radio
            key={item.label}
            value={item.value}
            label={item.label}
            style={{ lineHeight: 0 }}
          />
        ))}
      </Stack>
    </Radio.Group>
  )
}

export default PriceMenu
