import { Group, Badge } from 'ui/core'
import { CartItemVariant } from 'lib/cart/types'
import { formatVariantValue } from 'core/utils'

interface CartItemVariantsProps {
  variants: CartItemVariant[],
}

const CartItemVariants = ({ variants }: CartItemVariantsProps) =>
  variants.length ? (
    <Group gap={8}>
      {variants.map((variant) => (
        <Badge key={variant.label} color="gray" variant="outline">
          {formatVariantValue(variant.value)}
        </Badge>
      ))}
    </Group>
  ) : null

export default CartItemVariants
