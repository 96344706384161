import { Text, Group, Card, Grid, SimpleGrid, IconButton, Switch } from 'ui/core'
import { BankAccount } from 'model'
import { useDeleteBankAccount } from 'lib/bank-account'
import { useNotifications, usePopup } from 'core/hooks'
import { useAuth } from '@resellam/auth'
import { useSetPrimaryWithdrawalMethod } from 'lib/user'
import { useSWRConfig } from 'swr'

interface BankAccountCardProps {
  bankAccount: BankAccount,
  onDelete?: (bankAccount: BankAccount) => void,
  onSetPrimary?: (bankAccount: BankAccount) => void,
}

const BankAccountCard = ({ bankAccount, onSetPrimary, onDelete }: BankAccountCardProps) => {
  const { mutate } = useSWRConfig()
  const { user } = useAuth()
  const popup = usePopup()
  const notifications = useNotifications()
  const [deleteBankAccount, deleteBankAccountState] = useDeleteBankAccount()
  const [setPrimaryWithdrawalMethod] = useSetPrimaryWithdrawalMethod()
  const isPrimary = user?.primaryWithdrawalMethod === bankAccount.id

  const setPrimary = async () => {
    if (isPrimary)
      return
    const success = await mutate(user?.id, () => setPrimaryWithdrawalMethod({ id: bankAccount.id }), {
      optimisticData: { ...user, primaryWithdrawalMethod: bankAccount.id },
      rollbackOnError: true,
      populateCache: false,
    })
    notifications.show({
      variant: success ? 'success' : 'error',
      action: 'update',
      entity: 'primary payout method',
    })
    if (success)
      onSetPrimary?.(bankAccount)
  }

  const performDelete = async () => {
    const deleted = await deleteBankAccount({ bankAccountId: bankAccount.id })
    notifications.show({
      variant: deleted ? 'success' : 'error',
      action: 'delete',
      entity: 'payout method',
    })
    if (deleted)
      onDelete?.(bankAccount)
  }

  const openConfirmModal = () =>
    popup.show({
      variant: 'confirmation',
      title: 'Delete payout method',
      message: 'Are you sure you want to delete this payout method?',
      confirm: {
        title: 'Delete',
        onClick: () => performDelete(),
        color: 'red',
      },
    })

  return (
    <Card>
      <Grid justify="space-between" align="center">
        <Grid.Col span={{ base: 7, sm: 9, md: 10 }}>
          <SimpleGrid cols={{ base: 1, sm: 3 }}>
            <Text size="sm">{bankAccount.accountNumber}</Text>
            <Text size="sm">{bankAccount.accountName}</Text>
            <Text size="sm">{bankAccount.bank.name}</Text>
          </SimpleGrid>
        </Grid.Col>

        <Grid.Col span={{ base: 5, sm: 3, md: 2 }}>
          <Group justify="right" align="center" gap="md">
            <Switch
              title={isPrimary ? 'Primary' : 'Make primary'}
              checked={isPrimary}
              onChange={setPrimary}
              display="flex"
            />
            <IconButton
              name="trash"
              color="red"
              title="Delete"
              size="lg"
              onClick={openConfirmModal}
              loading={deleteBankAccountState.isRunning}
            />
          </Group>
        </Grid.Col>
      </Grid>
    </Card>
  )
}

export default BankAccountCard
