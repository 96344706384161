import { Group, Text, Stack, Highlight } from 'ui/core'
import { useEffect } from 'react'
import { AlgoliaProductHit } from 'lib/algolia'
import { Image } from 'core/components'

interface ProductHitProps {
  hit: AlgoliaProductHit,
  onInit?: () => void,
  offline?: boolean,
}

const ProductHit = ({ hit, offline, onInit }: ProductHitProps) => {
  useEffect(() => {
    onInit?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Group wrap="nowrap">
      <Image
        height={60}
        width={60}
        alt={hit.title}
        src={hit.image}
      />
      <Stack gap={4}>
        <Highlight
          lineClamp={1}
          color={offline ? 'gray' : undefined}
          highlight={[...(hit._highlightResult?.title?.matchedWords || [])]}
        >
          {hit.title}
        </Highlight>
        <Text
          lineClamp={1}
          color={offline ? 'gray' : undefined}
          inline
          size="sm"
        >
          By{' '}
          <Text
            inline
            size="sm"
            component="span"
            fw="bold"
          >
            {hit.brand?.title}
          </Text>{' '}
          in{' '}
          <Text
            inline
            size="sm"
            component="span"
            fw="bold"
          >
            {hit.category?.title}
          </Text>
        </Text>
      </Stack>
    </Group>
  )
}

export default ProductHit
