import { Text } from 'ui/core'
import { Address } from 'model'
import { fullName } from 'core/utils'
import { AddressLink, PhoneNumber } from 'core/components'

interface ShippingAddressProps {
  address: Address,
  testID?: string,
}

export const ShippingAddress = ({ testID, address }: ShippingAddressProps) => (
  <div data-testid={testID}>
    <Text lineClamp={1}>{fullName(address.firstName, address.lastName)}</Text>
    {address.phoneNumber && <PhoneNumber value={address.phoneNumber} />}
    {address.googlePlaceId && (
      <AddressLink placeName={address.street} placeId={address.googlePlaceId} />
    )}
  </div>
)

export default ShippingAddress
