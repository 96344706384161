import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions'
import { recentSearchesPlugin } from './recent-searches'
import { algoliaSearchClient } from '../algolia'
import { PRODUCTS_QUERY_SUGGESTIONS_INDEX } from '../constant'

export const querySuggestionsPlugin = createQuerySuggestionsPlugin({
  searchClient: algoliaSearchClient,
  indexName: PRODUCTS_QUERY_SUGGESTIONS_INDEX,
  getSearchParams({ state }) {
    return (
      recentSearchesPlugin.data?.getAlgoliaSearchParams({
        clickAnalytics: true,
        hitsPerPage: state.query ? 3 : 5,
      }) || {}
    )
  },
  // categoryAttribute: ['products', 'facets', 'exact_matches', 'category.title'],
  categoriesPerItem: 2,
  itemsWithCategories: 1,
})
