import { Seo } from 'components/Seo'
import { siteMetadata } from 'core/utils'
import { Category } from 'model'

interface CategorySeoProps {
  category: Category,
}

const CategorySeo = ({ category }: CategorySeoProps) => {
  const title = `${category.title} brands`
  return (
    <Seo
      title={title}
      description={`View all brands of ${category.title} you can sell on ${siteMetadata.name}`}
      canonical={`${siteMetadata.siteUrl}/sell/${category.slug}`}
      openGraph={{
        url: `${siteMetadata.siteUrl}/sell/${category.slug}`,
        title,
        images: [
          {
            url: `${siteMetadata.siteUrl}/api/og?title=${title}&image=${category.image}&size=280`,
            width: 1200,
            height: 630,
            alt: title,
          },
          {
            url: `${siteMetadata.siteUrl}/api/og?image=${category.image}&size=256&width=256&height=256`,
            width: 256,
            height: 256,
            alt: title,
          },
        ],
      }}
    />
  )
}

export default CategorySeo
