import { Button, ButtonProps } from 'ui/core'
import { useRouter } from 'next/router'
import { useAuth } from 'hooks/use-auth'
import { trackProducts } from 'lib/analytics'
import { useCart } from 'lib/cart'

interface CheckoutButtonProps extends ButtonProps {}

const CheckoutButton = ({ ...rest }: CheckoutButtonProps) => {
  const auth = useAuth()
  const router = useRouter()
  const cart = useCart({ checkout: true })

  const checkout = async () => {
    trackProducts('shop_checkout', {
      products: cart.items.map((item) => ({
        ...item.product,
        price: item.price,
        quantity: item.quantity,
        variant: item.variants?.map((variant) => variant.value).join(', '),
      })),
    })
    await auth.requireAuth()
    router.push('/shop/checkout')
  }

  return (
    <Button {...rest} onClick={checkout}>
      Checkout
    </Button>
  )
}

export default CheckoutButton
