import { SectionTitle, RowCarousel } from 'components'
import { SellOffer } from 'model'
import { Box } from 'ui/core'
import { SellOfferCard } from './SellOfferCard'

const SellOffers = ({ sellOffers }: { sellOffers: SellOffer[] }) => {
  return (
    <Box w="100%">
      <SectionTitle title="Sell offers" rightAction={{ href: '/sell', title: 'See all' }} />
      <RowCarousel slideSize={{ base: '50%' }} items={sellOffers}>
        {(sellOffer) => (
          <SellOfferCard key={sellOffer.id} sellOffer={sellOffer} />
        )}
      </RowCarousel>
    </Box>
  )
}

export default SellOffers
