import { trackEvent, trackProducts } from 'lib/analytics'
import { useCheckoutContext } from './CheckoutProvider'
import { CheckoutData } from './types'

export type CheckoutDataKey = keyof CheckoutData

export type CheckoutStep = keyof Pick<CheckoutData, 'shipping' | 'payment' | 'confirmation'>

const stepMap: Record<CheckoutStep, number> = {
  shipping: 0,
  payment: 1,
  confirmation: 2,
}

const useCheckout = () => {
  const checkout = useCheckoutContext()

  const proceed = (increment: number) => {
    checkout.setActiveStep(
      checkout.activeStep < 3 ? checkout.activeStep + increment : checkout.activeStep,
    )
  }

  const goToStep = (name: CheckoutStep) => {
    checkout.setActiveStep(stepMap[name])
  }

  const setData = (name: CheckoutDataKey, data?: Record<string, any>) => {
    checkout.setCheckoutData({ ...checkout.checkoutData, [name]: data })
  }

  const prevStep = (name?: CheckoutStep, data?: Record<string, any>) => {
    if (name && data) {
      setData(name, data)
    }
    proceed(-1)
  }

  const nextStep = (name: CheckoutStep, data: Record<string, any>) => {
    setData(name, data)
    proceed(1)
    trackProducts('checkout_progress', {
      products: checkout.cart.items.map((item) => ({
        ...item.product,
        price: item.price,
        quantity: item.quantity,
        variant: item.variants?.map((variant) => variant.value).join(', '),
      })),
    })
    trackEvent('set_checkout_option', {
      checkout_step: checkout.activeStep + 1,
      checkout_option: [...Object.entries(stepMap)].find(
        ([, val]) => val === checkout.activeStep + 1,
      )?.[0],
    })
  }

  return {
    total: checkout.total,
    cart: checkout.cart,
    data: checkout.checkoutData,
    activeStep: checkout.activeStep,
    createdOrder: checkout.createOrderState?.value?.order,
    isCreatingOrder: checkout.createOrderState?.isRunning,
    isCalculatingDeliveryFee: checkout.pickupEstimate?.isRunning,
    goToStep,
    nextStep,
    prevStep,
    setData,
    createOrder:
      !checkout.cart.isEmpty && checkout.activeStep === 2 ? checkout.createOrder : undefined,
    freeDelivery: checkout.freeDelivery,
  }
}

export default useCheckout
