import { useAuth } from '@resellam/auth'
import { Container, Modal } from 'ui/core'
import { PersonalInfoForm } from 'core/components'

const AccountSetup = () => {
  const auth = useAuth()
  return (
    <Modal
      fullScreen
      opened={!!(auth.user && !auth.user?.firstName)}
      onClose={() => {}}
      title="Finish setting up your account"
      withCloseButton={false}
      closeOnEscape={false}
      closeOnClickOutside={false}
    >
      <Container>
        <PersonalInfoForm submit={{ children: 'Continue', variant: 'filled' }} />
      </Container>
    </Modal>
  )
}

export default AccountSetup
