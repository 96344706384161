import { Box, BoxProps, Icon, IconName, Title, IconButton } from 'ui/core'
import { formatTestID } from 'core/utils'

export interface SellRequestOfferHeaderProps extends BoxProps {
  title: string,
  iconName: IconName,
  onBack?: () => void,
  testID?: string,
}

const SellRequestOfferHeader = ({
  title,
  iconName,
  onBack,
  testID,
  ...rest
}: SellRequestOfferHeaderProps) => (
  <Box
    mb="md"
    pos="relative"
    data-testid={testID}
    {...rest}
  >
    {onBack && (
      <IconButton
        title="Back"
        name="chevronLeft"
        onClick={onBack}
        testID={formatTestID(testID, 'back-button')}
        style={{ position: 'absolute', left: 0, top: 0 }}
      />
    )}
    <Title
      order={3}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 4 }}
      data-testid={formatTestID(testID, 'title')}
    >
      <Icon name={iconName} size="xl" />
      {title}
    </Title>
  </Box>
)

export default SellRequestOfferHeader
