import { UseConfigureProps, useConfigure as useISConfigure } from 'react-instantsearch-core'

export type ConfigureProps = Partial<UseConfigureProps>

export const useConfigure = (props: ConfigureProps = {}) => {
  useISConfigure({
    analytics: true,
    clickAnalytics: true,
    restrictSearchableAttributes: ['title'],
    typoTolerance: false,
    queryType: 'prefixNone',
    ...props,
  })
  return null
}

const Configure = (props: ConfigureProps) => {
  useConfigure(props)
  return null
}

export default Configure
