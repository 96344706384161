import { Alert as UIAlert, AlertProps as UIAlertProps, Icon, IconName } from 'ui/core'

type AlertStyle = 'warning' | 'info' | 'error'

interface AlertProps extends UIAlertProps {
  alertStyle: AlertStyle,
  withIcon?: boolean,
  testID?: string,
}

const iconMap: Record<AlertStyle, IconName> = {
  info: 'infoCircle',
  warning: 'alertTriangle',
  error: 'alertTriangle',
}

const colorMap: Record<AlertStyle, string> = {
  info: 'blue',
  warning: 'yellow',
  error: 'red',
}

const Alert = ({ testID, alertStyle, withIcon, ...rest }: AlertProps) => (
  <UIAlert
    icon={withIcon ? <Icon name={iconMap[alertStyle]} /> : undefined}
    color={colorMap[alertStyle]}
    data-testid={testID}
    {...rest}
  />
)

export default Alert
