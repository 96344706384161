import { Card, Text, Center } from 'ui/core'
import { CartItem as CartItemModel } from 'lib/cart/types'
import Link from 'next/link'
import { Money } from 'core/components'
import { ProductImage } from 'components'

interface ItemProps {
  item: CartItemModel,
}

const Item = ({ item }: ItemProps) => (
  <Card component={Link} href={`/shop/${item.product.slug}`}>
    <Center>
      <ProductImage size={150} product={item.product} />
    </Center>
    <Text mt="xs" align="center" lineClamp={1}>
      <Text inline component="span" size="sm">
        ({item.quantity}x)
      </Text>{' '}
      {item.product.title}
    </Text>
    <Money
      mt="xs"
      value={(item.product.price || 0) * item.quantity}
      size="lg"
      fw="bold"
      align="center"
    />
  </Card>
)

export default Item
