import { List, ListItemProps } from 'ui/core'
import { ReactNode } from 'react'
import classes from './HitListItem.module.css'

export interface HitListItemProps extends ListItemProps {
  children?: ReactNode,
}

const HitListItem = ({ children, ...rest }: HitListItemProps) => (
  <List.Item {...rest} className={classes.item}>
    {children}
  </List.Item>
)

export default HitListItem
