import { z } from 'zod'
import { useFormUI } from 'core/hooks'
import { useCheckout } from 'lib/checkout'
import { PaymentData } from 'lib/checkout/types'
import { StepHeader, PaymentMethods, Segments, Alert } from 'components'
import { PaymentMethod, PaymentType } from 'model'
import { formatTestID } from 'ui/utils'
import { Stack } from 'ui/core'

const schema = z.object({
  type: z.nativeEnum(PaymentType),
  method: z.nativeEnum(PaymentMethod, { required_error: 'Select payment method' }),
})

interface CheckoutPaymentProps {
  testID?: string,
}

const CheckoutPayment = ({ testID }: CheckoutPaymentProps) => {
  const checkout = useCheckout()
  const { render, form } = useFormUI<PaymentData & { type: PaymentType }>({
    schema,
    initialValues: {
      type: PaymentType.NOW,
      method: checkout.data.payment?.method,
    },
  })

  const next = (values: typeof form.values) => {
    checkout.nextStep('payment', schema.parse(values))
  }

  const back = () => {
    checkout.prevStep('payment', form.getValues())
  }

  const segmentsData = [
    {
      label: 'Pay now',
      value: PaymentType.NOW,
      children: (
        <Stack>
          <Alert alertStyle="info">
            Pay instantly and securely with your credit/debit card or via Bank transfer.
          </Alert>
          <PaymentMethods
            {...form.getInputProps('method')}
            methods={[PaymentMethod.CARD, PaymentMethod.BANK_TRANSFER]}
          />
        </Stack>
      ),
    },
    {
      label: 'Pay on delivery',
      value: PaymentType.ON_DELIVERY,
      children: (
        <Stack>
          <Alert alertStyle="warning">
            Please note that you will have to make payment before opening the package. Once the
            seal is broken, the item can only be returned if it is damaged, defective or has
            missing parts.
          </Alert>
          <PaymentMethods
            value={PaymentMethod.BANK_TRANSFER}
            methods={[PaymentMethod.BANK_TRANSFER]}
          />
        </Stack>
      ),
    },
  ]

  return render({
    onSubmit: next,
    onCancel: back,
    submitButtonProps: { children: 'Continue' },
    cancelButtonProps: { children: 'Back' },
    children: (
      <>
        <StepHeader title="Payment" testID={formatTestID(testID, 'title')} />
        <Segments
          {...form.getInputProps('type')}
          fullWidth
          onChange={(val) => {
            if (val === PaymentType.NOW)
              form.setFieldValue('method', PaymentMethod.CARD)
            if (val === PaymentType.ON_DELIVERY)
              form.setFieldValue('method', PaymentMethod.BANK_TRANSFER)
            form.setFieldValue('type', val as PaymentType)
          }}
          data={segmentsData}
          testID={formatTestID(testID, 'segments')}
        />
      </>
    ),
  })
}

export default CheckoutPayment
