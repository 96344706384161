import { z } from 'zod'
import { useFormUI } from 'core/hooks'
import { useCheckout } from 'lib/checkout'
import { addressSchema, formatTestID } from 'core/utils'
import { Address, ShippingMethod } from 'model'
import { AgentInfo, Segments, StepHeader } from 'components'
import { Card } from 'ui/core'
import { AddressAutocomplete } from 'components/AddressAutocomplete'

const schema = z
  .object({
    method: z.nativeEnum(ShippingMethod),
    address: addressSchema({ required_error: 'Choose address' })
      .extend({ id: z.string() })
      .optional(),
  })
  .refine(
    (data) => (data.method === ShippingMethod.DELIVERY ? !!data.address : true),
    {
      message: 'Choose address',
      path: ['address'],
    },
  )

interface CheckoutShippingProps {
  testID?: string,
}

const CheckoutShipping = ({ testID }: CheckoutShippingProps) => {
  const checkout = useCheckout()
  const { form, render } = useFormUI({
    schema,
    initialValues: {
      method: checkout.data.shipping?.method || ShippingMethod.DELIVERY,
      address: checkout.data.shipping?.address,
    },
  })

  const next = (values: typeof form.values) => {
    checkout.nextStep('shipping', schema.parse(values))
  }

  const handleChangeAddress = (address?: Address) => {
    form.setFieldValue('address', address)
    checkout.setData('shipping', { ...checkout.data.shipping, address })
    checkout.setData('payment', {})
  }

  return render({
    onSubmit: next,
    submitButtonProps: { children: 'Continue' },
    children: (
      <>
        <StepHeader title="Shipping" testID={formatTestID(testID, 'title')} />
        <Segments
          {...form.getInputProps('method')}
          fullWidth
          testID={formatTestID(testID, 'segments')}
          onChange={(val) => {
            if (val === ShippingMethod.PICKUP)
              handleChangeAddress(undefined)
            form.setFieldValue('method', val as ShippingMethod)
          }}
          data={[
            {
              label: 'Delivery',
              value: ShippingMethod.DELIVERY,
              children: (
                <AddressAutocomplete
                  {...form.getInputProps('address')}
                  required
                  label="Address"
                  onChange={handleChangeAddress}
                />
              ),
            },
            {
              label: 'Pickup',
              value: ShippingMethod.PICKUP,
              children: (
                <Card>
                  <AgentInfo />
                </Card>
              ),
            },
          ]}
        />
      </>
    ),
  })
}

export default CheckoutShipping
