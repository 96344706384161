import { Seo } from 'components/Seo'
import { siteMetadata } from 'core/utils'
import { Category, Brand } from 'model'

interface BrandsSeoProps {
  brand: Brand,
  category: Category,
}

const BrandSeo = ({ brand, category }: BrandsSeoProps) => {
  const title = `${brand.title} ${category.title}`
  return (
    <Seo
      title={title}
      description={`View all ${title} you can sell on ${siteMetadata.name}`}
      canonical={`${siteMetadata.siteUrl}/sell/${category.slug}/${brand.slug}`}
      openGraph={{
        url: `${siteMetadata.siteUrl}/sell/${category.slug}/${brand.slug}`,
        title,
        images: [
          {
            url: `${siteMetadata.siteUrl}/api/og?title=${brand.title}&image=${brand.logo}&size=280`,
            width: 1200,
            height: 630,
            alt: title,
          },
          {
            url: `${siteMetadata.siteUrl}/api/og?image=${brand.logo}&size=256&width=256&height=256`,
            width: 256,
            height: 256,
            alt: title,
          },
        ],
      }}
    />
  )
}

export default BrandSeo
