import { Menu, Icon } from 'ui/core'
import { useAuth } from '@resellam/auth'
import useLiveChat from 'hooks/use-live-chat'
import { useState } from 'react'
import Link from 'next/link'
import BottomTabActionIcon from './BottomTabActionIcon'
import NotificationsModal from '../NotificationsModal'
import NotificationsIndicator from '../NotificationsIndicator'

const MoreMenu = () => {
  const { signout } = useAuth()
  const liveChat = useLiveChat()
  const [isActive, setIsActive] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <>
      <Menu
        withArrow
        width={200}
        position="top-end"
        shadow="xl"
        radius="md"
        onOpen={() => setIsActive(true)}
        onClose={() => setIsActive(false)}
      >
        <Menu.Target>
          <NotificationsIndicator>
            <BottomTabActionIcon active={isActive}>
              <Icon name="dots" />
            </BottomTabActionIcon>
          </NotificationsIndicator>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            leftSection={<Icon name="bell" color="gray" />}
            onClick={() => setIsExpanded(true)}
          >
            <NotificationsIndicator variant="dot">Notifications</NotificationsIndicator>
          </Menu.Item>
          <Menu.Item
            leftSection={<Icon name="settings" color="gray" />}
            href="/settings/profile"
            component={Link}
          >
            Settings
          </Menu.Item>
          <Menu.Item
            leftSection={<Icon name="help" color="gray" />}
            onClick={() => liveChat.open()}
          >
            Help
          </Menu.Item>
          <Menu.Item leftSection={<Icon name="logout" color="gray" />} onClick={() => signout()}>
            Log out
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <NotificationsModal opened={isExpanded} onClose={() => setIsExpanded(false)} />
    </>
  )
}

export default MoreMenu
