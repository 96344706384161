import { useBreakpoint } from 'core/hooks'
import { Icon, ThemeIconProps, ThemeIcon } from 'ui/core'

export interface SearchIconProps extends Omit<ThemeIconProps, 'children'> {}

const SearchIcon = (props: SearchIconProps) => {
  const { isXs } = useBreakpoint()
  return (
    <ThemeIcon
      variant="gradient"
      radius="xl"
      size={isXs ? 'md' : 'lg'}
      {...props}
    >
      <Icon size={isXs ? 'sm' : 'md'} name="search" />
    </ThemeIcon>
  )
}

export default SearchIcon
