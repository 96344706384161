import {
  BRAND_FACET_ATTRIBUTE,
  CATEGORY_FACET_ATTRIBUTE,
  CONDITION_FACET_ATTRIBUTE,
} from 'lib/algolia'
import { Stack } from 'ui/core'
import { Panel } from '../Panel'
import { PriceMenu } from '../PriceMenu'
import { RefinementList } from '../RefinementList'

export type FiltersProps = {
  brands?: boolean,
  categories?: boolean,
  condition?: boolean,
  price?: boolean,
}

const Filters = ({ brands, categories, condition, price }: FiltersProps) => (
  <Stack>
    {brands && (
      <Panel header="Brands">
        <RefinementList attribute={BRAND_FACET_ATTRIBUTE} />
      </Panel>
    )}
    {categories && (
      <Panel header="Categories">
        <RefinementList attribute={CATEGORY_FACET_ATTRIBUTE} />
      </Panel>
    )}
    {condition && (
      <Panel header="Condition">
        <RefinementList attribute={CONDITION_FACET_ATTRIBUTE} />
      </Panel>
    )}
    {price && (
      <Panel header="Price">
        <PriceMenu />
      </Panel>
    )}
  </Stack>
)

export default Filters
