import { useNotifications } from 'core/hooks'
import { SellRequest } from 'model'
import { compressFile } from 'core/utils'
import { useUpdateSellRequest } from '../use-update-sell-request'
import { useSellRequest } from '../../contexts'

const useUploadSellRequestPhotos = () => {
  const notifications = useNotifications()
  const { sellRequest, updateSellRequest: mutateSellRequest } = useSellRequest()
  const [addMorePhotosTask, addMorePhotosState] = useUpdateSellRequest()
  const [uploadReceiptTask, uploadReceiptState] = useUpdateSellRequest()

  const uploadFiles = async (uploadFn: () => Promise<SellRequest | null>) => {
    if (!sellRequest?.id)
      return
    const result = await uploadFn()
    notifications.show({
      variant: result ? 'success' : 'error',
      action: 'update',
      entity: 'sell request',
    })
    if (result) {
      mutateSellRequest({
        images: [...(sellRequest.images || []), ...(result.images || [])],
      })
    }
  }

  const uploadReceipt = async (files: File[]) => {
    if (!sellRequest?.id)
      return
    const images = await Promise.all(
      files.map((raw) => compressFile(raw).then((file) => ({ file, isReceipt: true }))),
    )
    uploadFiles(() =>
      uploadReceiptTask({
        id: sellRequest?.id,
        images,
      }),
    )
  }

  const addMorePhotos = async (files: File[]) => {
    if (!sellRequest?.id)
      return
    const images = await Promise.all(
      files.map((raw) => compressFile(raw).then((file) => ({ file, isReceipt: false }))),
    )
    uploadFiles(() =>
      addMorePhotosTask({
        id: sellRequest?.id,
        images,
      }),
    )
  }

  return {
    uploadReceipt,
    uploadReceiptState,
    addMorePhotos,
    addMorePhotosState,
  }
}

export default useUploadSellRequestPhotos
