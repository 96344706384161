import { Catalogue, BrandCard, SectionTitle } from 'components'
import analytics from 'lib/analytics'
import { Brand } from 'model'
import { Box } from 'ui/core'

const ShopByBrands = ({ brands }: { brands: Brand[] }) => (
  <Box w="100%">
    <SectionTitle title="Shop brands" />
    <Catalogue cols={{ base: 1, sm: 2 }}>
      {brands.map((brand) => (
        <BrandCard
          key={brand.id}
          logo={brand.logo}
          h={{ base: 168, md: 202 }}
          href={`/shop?brand=${brand.title}`}
          onClick={() =>
            analytics.track('shop_view_brand', {
              promotions: [
                {
                  id: brand.id,
                  name: brand.title,
                },
              ],
            })}
        />
      ))}
    </Catalogue>
  </Box>
)

export default ShopByBrands
