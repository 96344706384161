import { siteMetadata } from 'core/utils'
import { DefaultSeo as NextDefaultSeo } from 'next-seo'

const DefaultSeo = () => (
  <NextDefaultSeo
    defaultTitle={siteMetadata.title}
    description={siteMetadata.description}
    canonical={siteMetadata.siteUrl}
    dangerouslySetAllPagesToNoIndex={process.env.NEXT_PUBLIC_APP_ENVIRONMENT !== 'production'}
    dangerouslySetAllPagesToNoFollow={process.env.NEXT_PUBLIC_APP_ENVIRONMENT !== 'production'}
    openGraph={{
      type: 'website',
      url: siteMetadata.siteUrl,
      site_name: siteMetadata.name,
    }}
    twitter={{
      handle: `@${siteMetadata.name}`,
      site: `@${siteMetadata.name}`,
      cardType: 'summary_large_image',
    }}
  />
)

export default DefaultSeo
