import { updateDoc } from 'firebase/firestore'
import useTask, { TaskState } from '@resellam/hooks/hooks/use-task'
import { getAuth, getDocumentRef, defaultConverter } from '@resellam/firebase'

type PrimaryAddress = {
  id: string | null,
}

const setPrimaryAddress = async (address: PrimaryAddress): Promise<boolean> => {
  const uid = getAuth().currentUser?.uid
  if (!uid)
    throw new Error('User is not logged in')
  const ref = getDocumentRef('users', uid).withConverter(defaultConverter)
  const data = {
    primaryAddressId: address.id,
  }
  await updateDoc(ref, data)
  return true
}

export const useSetPrimaryAddress = (): [
  (address: PrimaryAddress) => Promise<boolean | null>,
  TaskState<boolean>,
] => {
  const [taskFn, taskState] = useTask<boolean>()
  const run = (address: PrimaryAddress) =>
    taskFn(() => setPrimaryAddress(address))
  return [run, taskState]
}
