import { AppShellProps } from 'ui/core'
import { AppLayout as CoreAppLayout } from 'core/components'
import { useRouter } from 'next/router'
import { useBreakpoint } from 'core/hooks'
import { ReactNode, useEffect, useState } from 'react'
import { WELCOME_PAGE_PRODUCTS_SEARCH_ID } from 'helpers/constants'
import { useAuth } from 'hooks/use-auth'
import AppFooter from './AppFooter'
import BottomTabs from './BottomTabs'
import InstallPrompt from './InstallPrompt'
import { AppHeader } from './AppHeader'
import classes from './AppLayout.module.css'

export const useShowSearch = () => {
  const { user } = useAuth()
  const router = useRouter()
  const isHome = router.pathname === '/'
  const [showSearch, setShowSearch] = useState(!isHome)

  useEffect(() => {
    const search = document.getElementById(WELCOME_PAGE_PRODUCTS_SEARCH_ID)
    if (!search)
      return () => {}

    const observer = new IntersectionObserver(([entry]) => setShowSearch(!entry.isIntersecting), {
      root: null,
      threshold: 1,
      rootMargin: '0px',
    })

    observer.observe(search)
    return () => observer.unobserve(search)
  }, [isHome, user])

  return showSearch
}

export interface AppLayoutProps extends AppShellProps {
  rightSection?: ReactNode,
}

const AppLayout = ({ rightSection, children }: AppLayoutProps) => {
  const router = useRouter()
  const isHome = router.pathname === '/'
  const { isXs } = useBreakpoint()
  const { user } = useAuth()
  const showSearch = useShowSearch()

  return (
    <CoreAppLayout
      footerProps={{ children: <AppFooter className={classes.footer} /> }}
      headerProps={{
        withBorder: !isHome,
        className: isHome ? classes.homeHeader : undefined,
        children: <AppHeader rightSection={rightSection} showSearch={!isHome || showSearch} />,
      }}
    >
      {children}
      {isXs && user ? <BottomTabs /> : null}
      <InstallPrompt />
    </CoreAppLayout>
  )
}

export default AppLayout
