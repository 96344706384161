import { Welcome, SellOffers, RecentActivities, SwapOffers } from 'components/Home'
import { Product, SwapOffer, SellOffer } from 'model'
import { GetStaticProps, InferGetStaticPropsType } from 'next'
import { PRODUCT_FIELDS, getSanityClient } from '@resellam/sanity'
import { useAuth } from '@resellam/auth'
import { Container, Stack } from 'ui/core'
import { Deals } from 'components'
import { fetch, filter, order, slice } from '@aonawale/sanity-query'

const getProductBySlug = (slug: string) =>
  fetch<Product>(
    getSanityClient(),
    {
      constraints: [filter('_type', '==', 'product'), filter('slug.current', '==', slug), slice(0)],
    },
    PRODUCT_FIELDS,
  )

const calcProductPrice = (product?: Product) => (80 / 100) * (product?.price ?? 0)

const getSellOffers = async (): Promise<SellOffer[]> => {
  const iPhone13Pro = await getProductBySlug('iphone-13-pro')
  const iPhone13ProMax = await getProductBySlug('iphone-13-pro-max')
  const iPhone14Pro = await getProductBySlug('iphone-14-pro')
  const iPhone14ProMax = await getProductBySlug('iphone-14-pro-max')
  return [
    {
      id: Math.random().toString(36).slice(2, 11),
      product: iPhone13Pro,
      price: calcProductPrice(iPhone13Pro),
    },
    {
      id: Math.random().toString(36).slice(2, 11),
      product: iPhone14Pro,
      price: calcProductPrice(iPhone14Pro),
    },
    {
      id: Math.random().toString(36).slice(2, 11),
      product: iPhone13ProMax,
      price: calcProductPrice(iPhone13ProMax),
    },
    {
      id: Math.random().toString(36).slice(2, 11),
      product: iPhone14ProMax,
      price: calcProductPrice(iPhone14ProMax),
    },
  ]
}

const getProductDeals = () =>
  fetch<Product[]>(
    getSanityClient(),
    {
      constraints: [
        filter('_type', '==', 'product'),
        filter('active', '==', true),
        filter('featured', '==', true),
        filter('discount', '!=', null),
        filter('references(*[_type=="discount" && dateTime(endDate) >= dateTime(now())]._id)'),
        order('_updatedAt', 'asc'),
      ],
    },
    PRODUCT_FIELDS,
  )

const getSwapOffers = async (): Promise<SwapOffer[]> => {
  const iPhone13Pro = await getProductBySlug('iphone-13-pro')
  const iPhone12ProMax = await getProductBySlug('iphone-12-pro-max')
  const iPhone13ProMax = await getProductBySlug('iphone-13-pro-max')
  const iPhone14Pro = await getProductBySlug('iphone-14-pro')
  const iPhone14ProMax = await getProductBySlug('iphone-14-pro-max')
  return [
    {
      id: Math.random().toString(36).slice(2, 11),
      products: [iPhone13ProMax, iPhone14ProMax],
      price: (iPhone14ProMax?.price ?? 0) - calcProductPrice(iPhone13ProMax),
    },
    {
      id: Math.random().toString(36).slice(2, 11),
      products: [iPhone13Pro, iPhone14Pro],
      price: (iPhone14Pro?.price ?? 0) - calcProductPrice(iPhone13Pro),
    },
    {
      id: Math.random().toString(36).slice(2, 11),
      products: [iPhone12ProMax, iPhone13ProMax],
      price: (iPhone13ProMax?.price ?? 0) - calcProductPrice(iPhone12ProMax),
    },
  ]
}

export const getStaticProps: GetStaticProps<{
  sellOffers: SellOffer[],
  products: Product[],
  swapOffers: SwapOffer[],
}> = async () => {
  const sellOffers = await getSellOffers()
  const products = await getProductDeals()
  const swapOffers = await getSwapOffers()

  return {
    props: {
      sellOffers,
      products,
      swapOffers,
    },
  }
}

const HomePage = ({
  sellOffers,
  products,
  swapOffers,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { user } = useAuth()
  return (
    <>
      <Welcome />
      <Container style={{ marginTop: 48 }}>
        <Stack gap={48}>
          {user && <RecentActivities />}
          <SellOffers sellOffers={sellOffers} />
          <Deals title="Shop Deals" products={products} />
          <SwapOffers swapOffers={swapOffers} />
        </Stack>
      </Container>
    </>
  )
}

export default HomePage
