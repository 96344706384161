import { useCartContext } from './CartProvider'
import { CartItem } from './types'

const useCartItems = () => {
  const { items, setItems } = useCartContext()

  const findItem = (itemToFind: CartItem) =>
    items.find(
      (item: CartItem) =>
        itemToFind.product.id === item.product.id
        && itemToFind.variants.length === item.variants.length
        && itemToFind.variants.every((itemToFindVariant) =>
          item.variants.find(
            (variant) =>
              variant.label === itemToFindVariant.label
              && variant.value === itemToFindVariant.value,
          ),
        ),
    )

  const add = (newItem: CartItem) => {
    let updatedItems = [...items]

    const itemAlreadyInCart = findItem(newItem)

    if (itemAlreadyInCart) {
      itemAlreadyInCart.quantity += newItem.quantity
    } else {
      updatedItems = [newItem, ...items]
    }

    setItems(updatedItems)
  }

  const remove = (itemToRemove: CartItem) => {
    const updatedItems = items.filter((item: CartItem) => item !== itemToRemove)
    setItems(updatedItems)
  }

  const setQuantity = (itemToSet: CartItem, quantity: number) => {
    const itemAlreadyInCart = items.find((item: CartItem) => itemToSet === item)
    if (itemAlreadyInCart) {
      itemAlreadyInCart.quantity = quantity
      setItems([...items])
    }
  }

  const setCheckout = (itemToSet: CartItem, checkout: boolean) => {
    const itemAlreadyInCart = items.find((item: CartItem) => itemToSet === item)
    if (itemAlreadyInCart) {
      itemAlreadyInCart.checkout = checkout
      setItems([...items])
    }
  }

  const clearCheckedout = () => {
    setItems(items.filter((item) => !item.checkout))
  }

  return {
    add,
    remove,
    setQuantity,
    setCheckout,
    clearCheckedout,
  }
}

export default useCartItems
