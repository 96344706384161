import { Title } from 'ui/core'

export interface StepHeaderProps {
  title: string,
  testID?: string,
}

const StepHeader = ({ title, testID }: StepHeaderProps) => (
  <Title order={2} mb="md" data-testid={testID}>
    {title}
  </Title>
)

export default StepHeader
