import { Flex, Icon, Radio, RadioGroupProps, Stack, Text } from 'ui/core'
import { PaymentMethod as PaymentMethodModel } from 'model'
import { formatTestID, paymentMethodMap } from 'core/utils'
import classes from './PaymentMethods.module.css'

interface PaymentMethodsProps extends Omit<RadioGroupProps, 'children' | 'onChange'> {
  methods: PaymentMethodModel[],
  onChange?: (value: PaymentMethodModel) => void,
  testID?: string,
}

const PaymentMethods = ({ testID, methods, onChange, ...rest }: PaymentMethodsProps) => {
  const cards = methods.map((method) => (
    <Radio.Card
      className={classes.radioCard}
      key={method}
      radius="md"
      value={method}
      p="md"
      data-testid={formatTestID(testID, method)}
    >
      <Flex gap="md" align="center">
        <Radio.Indicator />
        <Flex justify="space-between" flex={1}>
          <Text>{paymentMethodMap[method].title}</Text>
          <Icon name={paymentMethodMap[method].icon} size="lg" />
        </Flex>
      </Flex>
    </Radio.Card>
  ))

  return (
    <Radio.Group {...rest} onChange={(val) => onChange?.(val as PaymentMethodModel)} data-testid={testID}>
      <Stack gap="xs">
        {cards}
      </Stack>
    </Radio.Group>
  )
}

export default PaymentMethods
