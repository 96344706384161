import { ReactNode, createContext, useState, useContext } from 'react'
import { useLocalStorage } from 'ui/hooks'
import { CartItem } from './types'

export interface CartContextValue {
  isOpen: boolean,
  isHidden: boolean,
  setIsOpen: (state: boolean) => void,
  setIsHidden: (state: boolean) => void,
  items: CartItem[],
  setItems: (items: CartItem[]) => void,
}

export const CartContext = createContext<CartContextValue | undefined>(undefined)

interface CartProviderProps {
  children: ReactNode,
  items?: CartItem[],
}

const deserialize = (value: string | undefined) => {
  if (!value)
    return []
  try {
    const json = JSON.parse(value) as CartItem[]
    return json.filter((item) => item.product && item.quantity && item.price)
  } catch {
    return []
  }
}

const CartProvider = ({ items: externalItems, ...props }: CartProviderProps) => {
  const [isHidden, setIsHidden] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [items, setItems] = useLocalStorage<CartItem[]>({
    key: '@resellam/shop/cart/items',
    defaultValue: [],
    getInitialValueInEffect: true,
    deserialize,
  })

  const cartContextValue: CartContextValue = {
    isOpen,
    isHidden,
    setIsOpen,
    setIsHidden,
    items: externalItems || items || [],
    setItems: externalItems ? () => {} : setItems,
  }

  return <CartContext.Provider value={cartContextValue} {...props} />
}

export default CartProvider

export const useCartContext = (): CartContextValue => {
  const context = useContext(CartContext)
  if (!context) {
    throw new Error('useCartContext must be used within a CartProvider')
  }
  return context
}
