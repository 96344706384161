import {
  Box,
  Button,
  ButtonProps,
  Card,
  Divider,
  Group,
  Icon,
  IconName,
} from 'ui/core'
import { useBreakpoint } from 'core/hooks'
import { SearchIcon } from '../SearchIcon'
import { SearchType } from '../types'
import { useProductSearch } from '../useProductSearch'
import { forwardRef } from 'react'

export interface ProductSearchTriggerProps {
  size?: ButtonProps['size'],
  onTrigger?: (val: SearchType) => void,
}

const TriggerButton = ({
  iconName,
  active,
  ...rest
}: ButtonProps & {
  iconName: IconName,
  onClick?: () => void,
  active?: boolean,
}) => {
  const { isXs } = useBreakpoint()
  return (
    <Button
      fullWidth
      radius={0}
      mih={{ sm: '42px' }}
      leftSection={
        isXs ? null : <Icon name={iconName} size="lg" />
      }
      variant={active ? 'filled' : 'subtle'}
      px={0}
      {...rest}
    />
  )
}

const ProductSearchTrigger = forwardRef(
  ({ size, onTrigger }: ProductSearchTriggerProps, ref: any) => {
    const { searchType, setSearchType } = useProductSearch()
    const { isXs } = useBreakpoint()

    const handleClick = (type: SearchType) => {
      setSearchType(type)
      onTrigger?.(type)
    }

    return (
      <Card
        ref={ref}
        p={0}
        radius="xl"
        shadow="xs"
        w="100%"
        bg="white"
      >
        <Group
          wrap="nowrap"
          gap={0}
          style={{ flex: 1 }}
          h="100%"
        >
          <TriggerButton
            size={size}
            iconName="shoppingBag"
            onClick={() => handleClick('Buy')}
            active={searchType === 'Buy'}
          >
            Buy
          </TriggerButton>
          <Divider orientation="vertical" />
          <TriggerButton
            size={size}
            iconName="cash"
            onClick={() => handleClick('Sell')}
            active={searchType === 'Sell'}
          >
            Sell
          </TriggerButton>
          <Divider orientation="vertical" />
          <TriggerButton
            rightSection={
              isXs ? null : (
                <Box style={{ position: 'absolute', right: '4px' }}>
                  <SearchIcon />
                </Box>
              )
            }
            size={size}
            iconName="repeat"
            onClick={() => handleClick('Swap')}
            active={searchType === 'Swap'}
          >
            Swap
          </TriggerButton>
        </Group>
      </Card>
    )
  },
)

export default ProductSearchTrigger
